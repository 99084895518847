import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
export type Bd4TravelRecommendationInfoFragment = {
  __typename?: 'BD4TravelRecommendationInfo';
  modelId: string;
  placementId: string;
  strategy: string;
};

export const Bd4TravelRecommendationInfoFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BD4TravelRecommendationInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BD4TravelRecommendationInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'modelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placementId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
