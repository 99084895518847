import type { ICanonicalTravelDestination } from '@hotelplan/components.common.travel-destination';
import { GeoObject, TravelDestinationType } from '@hotelplan/graphql.types';
import { GetSrlMapPinsQuery } from 'graphql/srlMap/GetSRLMapPins.generated';
import {
  SrlMapGeoPinFragment,
  SrlMapProductPinFragment,
} from 'graphql/srlMap/SRLMapFragments.generated';

export function mapSrlMapDataToMapPins(data?: GetSrlMapPinsQuery | null) {
  const items: (SrlMapGeoPinFragment | SrlMapProductPinFragment)[] = [];

  if (!data) return items;

  const allPins = data.srl.search.mapPins;

  if (allPins.productPins.length) {
    allPins.productPins.forEach(item => {
      items.push(item);
    });
  } else if (allPins.geoPins.length) {
    allPins.geoPins.forEach(item => {
      items.push(item);
    });
  }

  return items;
}

export function mapGeoObjectToCanonicalDestination(
  object: GeoObject
): ICanonicalTravelDestination {
  return {
    canonicalName: object.name,
    name: object.name,
    type: (object.type as unknown) as TravelDestinationType,
    id: object.id,
  };
}
