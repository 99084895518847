import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { SearchControlFragmentDoc } from './SearchControl.generated';
import { FlightSearchControlFragmentDoc } from 'graphql/flight/FlightSearchControl.generated';
export type UnionSearchControlFlightSearchControlComponentFragment = {
  __typename: 'FlightSearchControlComponent';
  travelType: Types.TravelType;
  flightType: Types.FlightType;
  travellers: {
    __typename?: 'Travellers';
    adults: number;
    childrenDobs?: Array<string> | null;
  };
  flightPartitions?: Array<{
    __typename?: 'FlightPartition';
    flightClass: Types.FlightClass;
    departureAirport?: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    } | null;
    arrivalAirport?: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    } | null;
    travelPeriod: {
      __typename?: 'ExactTravelPeriod';
      returnDate: string;
      departureDate: string;
    };
  }> | null;
};

export type UnionSearchControlSearchControlComponentFragment = {
  __typename: 'SearchControlComponent';
  travelType: Types.TravelType;
  rooms: number;
  period: {
    __typename?: 'TravelPeriodComponent';
    type: Types.SearchPeriodType;
    exactPeriod: {
      __typename?: 'ExactTravelPeriod';
      departureDate: string;
      returnDate: string;
    };
    flexiblePeriod: {
      __typename?: 'FlexibleTravelPeriod';
      departureDate: string;
      maxDuration: number;
      minDuration: number;
      returnDate: string;
    };
  };
  destinations?: Array<{
    __typename?: 'TravelDestination';
    id: string;
    name: string;
    type: Types.TravelDestinationType;
    mythosCode?: string | null;
    location?: {
      __typename?: 'GeoLocation';
      airport?: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      } | null;
      continent?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      countryGroup?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      country?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      region?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      destination?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      resort?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
    } | null;
  }> | null;
  travellers: {
    __typename?: 'Travellers';
    adults: number;
    childrenDobs?: Array<string> | null;
  };
  travellersDistribution?: {
    __typename?: 'TravellersDistribution';
    rooms: Array<{
      __typename?: 'RoomDistribution';
      adultsAmount: number;
      childrenIdx?: Array<number> | null;
    }>;
  } | null;
};

export type UnionSearchControlFragment =
  | UnionSearchControlFlightSearchControlComponentFragment
  | UnionSearchControlSearchControlComponentFragment;

export const UnionSearchControlFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UnionSearchControl' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchControlUnion' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SearchControl' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FlightSearchControl' },
          },
        ],
      },
    },
    ...SearchControlFragmentDoc.definitions,
    ...FlightSearchControlFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
