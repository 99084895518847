import React from 'react';
import styled from 'styled-components';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useGetThemeContentQuery } from 'graphql/theme/GetThemeContent.generated';
import ThemeMapAccess from './ThemeMapAccess';

const ThemeMapAccessWrapper = styled.div(
  sx2CssThemeFn({
    mb: [5, '50px'],
    px: [4, 0],
  })
);

export default function ThemeMapAccessBlock(): React.ReactElement {
  const { id } = useObjectId();

  const { data: contentData } = useGetThemeContentQuery({
    variables: {
      id,
    },
  });

  const showMapAccess: boolean | undefined =
    contentData?.theme.staticContent.mapAccess;

  if (showMapAccess === false) return null;

  return (
    <ThemeMapAccessWrapper>
      <ThemeMapAccess />
    </ThemeMapAccessWrapper>
  );
}
