import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import {
  TypedTextComponentFragmentDoc,
  TextComponentFragmentDoc,
} from 'graphql/text/TextComponent.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { NewsletterSignupComponentFragmentDoc } from 'graphql/newsletter/NewsletterSignupComponent.generated';
import { GeoRecommendationFragmentDoc } from 'graphql/geo/GeoRecommendation.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { ThemeRecommendationFragmentDoc } from 'graphql/theme/ThemeRecommendation.generated';
import { MediaGalleryItemFragmentDoc } from 'graphql/media/MediaGalleryItem.generated';
import { ProductRecommendationFragmentDoc } from 'graphql/product/ProductRecommendation.generated';
import { MarketingRecommendationFragmentDoc } from 'graphql/marketing/MarketingRecommendation.generated';
import { LinkFragmentFragmentDoc } from 'graphql/link/Link.generated';
import { BlogArticleFragmentDoc } from 'graphql/blogArticle/BlogArticle.generated';
import { Bd4TravelRecommendationFragmentDoc } from 'graphql/bd4Travel/BD4TravelRecommendation.generated';
import { Bd4TravelRecommendationTrackingFragmentDoc } from 'graphql/bd4Travel/BD4TravelRecommendationTracking.generated';
import { FlightRecommendationWithPriceFragmentDoc } from 'graphql/flight/FlightRecommendationWithPrice.generated';
import { FlightRecommendationWithoutPriceFragmentDoc } from 'graphql/flight/FlightRecommendationWithoutPrice.generated';
import {
  SrlMapGeoPinFragmentDoc,
  SrlMapProductPinFragmentDoc,
} from 'graphql/srlMap/SRLMapFragments.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetDynamicComponentsQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  uri: Types.Scalars['String'];
  pageType: Types.PageType;
  id?: Types.InputMaybe<Types.Scalars['String']>;
  searchQuery?: Types.InputMaybe<Types.Scalars['String']>;
  gss?: Types.InputMaybe<Types.GlobalSearchStateInput>;
}>;

export type GetDynamicComponentsQuery = {
  __typename?: 'Query';
  components: Array<
    | {
        __typename?: 'AccordionComponent';
        anchor?: string | null;
        bottomBackgroundColor?: string | null;
        bottomFontColor?: string | null;
        bottomMainText?: string | null;
        bottomMainTitle?: string | null;
        topBackgroundColor?: string | null;
        topFontColor?: string | null;
        topMainText?: string | null;
        topMainTitle?: string | null;
        components: Array<
          | { __typename?: 'AccordionComponent' }
          | { __typename?: 'AgencyContactComponent' }
          | { __typename?: 'AgencyRecommendation' }
          | { __typename?: 'BD4TravelRecommendationGroup' }
          | { __typename?: 'BenefitsComponent' }
          | { __typename?: 'BlogArticleRecommendation' }
          | { __typename?: 'BrandsComponent' }
          | { __typename?: 'ClimateComponent' }
          | { __typename?: 'ContactModuleComponent' }
          | { __typename?: 'EmployeesComponent' }
          | {
              __typename?: 'FaqComponent';
              items: Array<{
                __typename?: 'TextComponent';
                fontColor?: string | null;
                backgroundColor?: string | null;
                title: string;
                text: string;
                bottomPadding?: string | null;
                topPadding?: string | null;
              }>;
            }
          | { __typename?: 'FlightWithoutPriceRecommendation' }
          | { __typename?: 'FlightWithPriceRecommendation' }
          | { __typename?: 'GeoChildrenComponent' }
          | { __typename?: 'GeoGalleryComponent' }
          | { __typename?: 'GeoRecommendation' }
          | { __typename?: 'HeroMediaGallery' }
          | { __typename?: 'ImageTextComponent' }
          | { __typename?: 'LinkListComponent' }
          | { __typename?: 'MarketingRecommendation' }
          | { __typename?: 'MarketingTeaserComponent' }
          | { __typename?: 'MediaGallery' }
          | { __typename?: 'NewsArticlesComponent' }
          | { __typename?: 'NewsletterSignupComponent' }
          | { __typename?: 'OfferButtonComponent' }
          | { __typename?: 'OffersMapPlaceholderComponent' }
          | { __typename?: 'ProductGalleryWithMapComponent' }
          | { __typename?: 'ProductRecommendation' }
          | { __typename?: 'RequestButtonComponent' }
          | { __typename?: 'ResortTopHotelsComponent' }
          | { __typename?: 'SrlButtonPlaceholderComponent' }
          | { __typename?: 'SrlMapPinsComponent' }
          | { __typename?: 'StaticBlogRecommendation' }
          | { __typename?: 'StaticGeoRecommendation' }
          | { __typename?: 'StaticProductRecommendation' }
          | { __typename?: 'StaticThemeRecommendation' }
          | { __typename?: 'TextComponent' }
          | { __typename?: 'ThemeRecommendation' }
          | { __typename?: 'UspBoxesComponent' }
        >;
      }
    | { __typename?: 'AgencyContactComponent' }
    | { __typename?: 'AgencyRecommendation' }
    | {
        __typename?: 'BD4TravelRecommendationGroup';
        anchor?: string | null;
        name?: string | null;
        mainText?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        items: Array<{
          __typename?: 'BD4TravelRecommendation';
          adults: number;
          children: number;
          departureDate: string;
          returnDate: string;
          disrupter?: string | null;
          distanceInfo?: string | null;
          duration: number;
          fromTo?: string | null;
          giata: string;
          hpRating?: number | null;
          mapZoom: number;
          name: string;
          mealType: string;
          productClass: Types.ProductClass;
          travelType?: string | null;
          type: Types.ProductType;
          offerId: string;
          inWishlist: boolean;
          transferIncluded?: boolean | null;
          taRating?: number | null;
          taReviews?: number | null;
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
          featureSummary: Array<{
            __typename?: 'ProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'ProductFeature';
              id: string;
              name: string;
              enabled?: boolean | null;
            }>;
          }>;
          geoBreadcrumbs: Array<{
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          }>;
          image: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
          price: { __typename?: 'Price'; amount: string; currency: string };
          wishlistItem?: {
            __typename?: 'WishlistItem';
            id: string;
            inWishlist: boolean;
          } | null;
          transferPrice?: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          } | null;
        }>;
        tracking?: {
          __typename?: 'BD4TravelRecommendationTracking';
          productCount?: number | null;
          productIds?: Array<string> | null;
          ptoken?: string | null;
          status: Types.Bd4TravelRecommendationStatus;
          info?: {
            __typename?: 'BD4TravelRecommendationInfo';
            modelId: string;
            placementId: string;
            strategy: string;
          } | null;
        } | null;
      }
    | { __typename?: 'BenefitsComponent' }
    | {
        __typename?: 'BlogArticleRecommendation';
        anchor?: string | null;
        name?: string | null;
        mainText?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        items: Array<{
          __typename?: 'BlogArticleRecommendationItem';
          date: string;
          tag: string;
          text: string;
          title: string;
          image: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              };
        }>;
      }
    | { __typename?: 'BrandsComponent' }
    | {
        __typename?: 'ClimateComponent';
        anchor?: string | null;
        mainText?: string | null;
        sunHoursPerYear: string;
        backgroundColor?: string | null;
        charts: Array<{
          __typename?: 'ClimateChart';
          type: Types.ClimateChartType;
          title: string;
          entries: Array<{
            __typename?: 'ClimateChartEntry';
            month: string;
            value: number;
          }>;
        }>;
      }
    | { __typename?: 'ContactModuleComponent' }
    | { __typename?: 'EmployeesComponent' }
    | {
        __typename?: 'FaqComponent';
        items: Array<{
          __typename?: 'TextComponent';
          title: string;
          text: string;
          bottomPadding?: string | null;
          topPadding?: string | null;
        }>;
      }
    | {
        __typename?: 'FlightWithoutPriceRecommendation';
        anchor?: string | null;
        name?: string | null;
        mainText?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        items: Array<{
          __typename?: 'FlightRecommendationWithoutPriceItem';
          title: string;
          subtitle: string;
          link:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              };
          price: { __typename?: 'Price'; amount: string; currency: string };
          media:
            | {
                __typename?: 'ExternalMediaItem';
                resource: string;
                title: string;
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'ImageMediaItem';
                title: string;
                image: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'MusicMediaItem';
                resource: string;
                title: string;
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'PdfMediaItem';
                resource: string;
                title: string;
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'VideoMediaItem';
                resource: string;
                title: string;
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              };
        }>;
      }
    | {
        __typename?: 'FlightWithPriceRecommendation';
        anchor?: string | null;
        name?: string | null;
        mainText?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        items: Array<{
          __typename?: 'FlightRecommendationWithPriceItem';
          title: string;
          amount: string;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
          media:
            | {
                __typename?: 'ExternalMediaItem';
                resource: string;
                title: string;
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'ImageMediaItem';
                title: string;
                image: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'MusicMediaItem';
                resource: string;
                title: string;
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'PdfMediaItem';
                resource: string;
                title: string;
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'VideoMediaItem';
                resource: string;
                title: string;
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              };
          offer: {
            __typename?: 'FlightOffer';
            offerId: string;
            waitingScreenImage: string;
            pricePerPerson: {
              __typename?: 'Price';
              amount: string;
              currency: string;
            };
            totalPrice: {
              __typename?: 'Price';
              amount: string;
              currency: string;
            };
            forwardFlight: {
              __typename?: 'Flight';
              departureTime: any;
              arrivalTime: any;
              duration: string;
              arrivalDate: any;
              departureDate: any;
              overnight: boolean;
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              segments: Array<{
                __typename?: 'FlightSegment';
                airportWaiting?: string | null;
                arrivalDate: any;
                arrivalTime: any;
                departureDate: any;
                departureTime: any;
                flightClass: Types.FlightClass;
                flightNumber: string;
                segmentDuration: string;
                airline: {
                  __typename?: 'Airline';
                  airlineLogo: string;
                  iata: string;
                  name: string;
                };
                arrival: {
                  __typename?: 'Airport';
                  iata?: string | null;
                  name?: string | null;
                };
                departure: {
                  __typename?: 'Airport';
                  iata?: string | null;
                  name?: string | null;
                };
              }>;
            };
            returnFlight?: {
              __typename?: 'Flight';
              departureTime: any;
              arrivalTime: any;
              duration: string;
              arrivalDate: any;
              departureDate: any;
              overnight: boolean;
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              segments: Array<{
                __typename?: 'FlightSegment';
                airportWaiting?: string | null;
                arrivalDate: any;
                arrivalTime: any;
                departureDate: any;
                departureTime: any;
                flightClass: Types.FlightClass;
                flightNumber: string;
                segmentDuration: string;
                airline: {
                  __typename?: 'Airline';
                  airlineLogo: string;
                  iata: string;
                  name: string;
                };
                arrival: {
                  __typename?: 'Airport';
                  iata?: string | null;
                  name?: string | null;
                };
                departure: {
                  __typename?: 'Airport';
                  iata?: string | null;
                  name?: string | null;
                };
              }>;
            } | null;
          };
        }>;
      }
    | {
        __typename?: 'GeoChildrenComponent';
        anchor?: string | null;
        backgroundColor?: string | null;
        mainText?: string | null;
        children: Array<{
          __typename?: 'GeoChildComponent';
          title: string;
          image: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
        }>;
      }
    | {
        __typename?: 'GeoGalleryComponent';
        anchor?: string | null;
        backgroundColor?: string | null;
        mainText?: string | null;
        mediaItems: Array<
          | {
              __typename?: 'ExternalMediaItem';
              resource: string;
              title: string;
              thumbnail: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              preview: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'ImageMediaItem';
              title: string;
              thumbnail: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              image: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'MusicMediaItem';
              resource: string;
              title: string;
              thumbnail: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              preview: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'PdfMediaItem';
              resource: string;
              title: string;
              thumbnail: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              preview: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'VideoMediaItem';
              resource: string;
              title: string;
              thumbnail: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              preview: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
        >;
      }
    | {
        __typename?: 'GeoRecommendation';
        anchor?: string | null;
        name?: string | null;
        mainText?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        items: Array<{
          __typename?: 'GeoRecommendationItem';
          count: number;
          country?: string | null;
          adults: number;
          children: number;
          averageTemp?: string | null;
          duration: number;
          disrupter?: string | null;
          flightDuration?: string | null;
          mapZoom: number;
          image: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
          moreInfo?: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          } | null;
          geoObject: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          };
          perPersonPrice: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        }>;
      }
    | { __typename?: 'HeroMediaGallery' }
    | {
        __typename?: 'ImageTextComponent';
        text: string;
        imagePlacement: Types.ImagePlacement;
        image?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      }
    | { __typename?: 'LinkListComponent' }
    | {
        __typename?: 'MarketingRecommendation';
        anchor?: string | null;
        name?: string | null;
        mainText?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        items: Array<{
          __typename?: 'MarketingRecommendationItem';
          title: string;
          subtitle: string;
          link:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              };
          preview:
            | {
                __typename?: 'ExternalMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'ImageMediaItem';
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                image: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'MusicMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'PdfMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'VideoMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              };
        }>;
      }
    | {
        __typename?: 'MarketingTeaserComponent';
        anchor?: string | null;
        backgroundColorAlignment?: string | null;
        backgroundColor?: string | null;
        backgroundTheme?: string | null;
        bottomPadding?: string | null;
        topPadding?: string | null;
        buttonTitle: string;
        mainText?: string | null;
        title: string;
        buttonLink:
          | {
              __typename?: 'ExternalLink';
              openMethod: Types.OpenLinkMethod;
              url: string;
              caption?: string | null;
            }
          | {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            };
        teaserImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        logoImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'MediaGallery';
        anchor?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        mediaItems: Array<
          | {
              __typename?: 'ExternalMediaItem';
              resource: string;
              title: string;
              thumbnail: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              preview: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'ImageMediaItem';
              title: string;
              thumbnail: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              image: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'MusicMediaItem';
              resource: string;
              title: string;
              thumbnail: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              preview: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'PdfMediaItem';
              resource: string;
              title: string;
              thumbnail: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              preview: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'VideoMediaItem';
              resource: string;
              title: string;
              thumbnail: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              preview: {
                __typename?: 'Image';
                alt: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
        >;
      }
    | { __typename?: 'NewsArticlesComponent' }
    | {
        __typename?: 'NewsletterSignupComponent';
        backgroundColor?: string | null;
        buttonText: string;
        disclaimerText: string;
        fontColor?: string | null;
        mainText?: string | null;
        successMessageText: string;
        successMessageTitle: string;
        titleText: string;
        image?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        successMessageTextIcon?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'OfferButtonComponent';
        anchor?: string | null;
        caption: string;
        backgroundColor?: string | null;
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
      }
    | { __typename?: 'OffersMapPlaceholderComponent' }
    | { __typename?: 'ProductGalleryWithMapComponent' }
    | {
        __typename?: 'ProductRecommendation';
        anchor?: string | null;
        name?: string | null;
        mainText?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        items: Array<{
          __typename?: 'ProductRecommendationItem';
          giata: string;
          name: string;
          adults: number;
          children: number;
          hpRating?: number | null;
          taRating?: number | null;
          taReviews?: number | null;
          type: Types.ProductType;
          distanceInfo?: string | null;
          duration: number;
          disrupter?: string | null;
          departureDate: string;
          returnDate: string;
          fromTo?: string | null;
          productClass: Types.ProductClass;
          mealType: string;
          offerId: string;
          inWishlist: boolean;
          transferIncluded?: boolean | null;
          image: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          geoBreadcrumbs: Array<{
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          }>;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
          featureSummary: Array<{
            __typename?: 'ProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'ProductFeature';
              id: string;
              name: string;
              enabled?: boolean | null;
            }>;
          }>;
          price: { __typename?: 'Price'; amount: string; currency: string };
          wishlistItem?: {
            __typename?: 'WishlistItem';
            id: string;
            inWishlist: boolean;
          } | null;
          transferPrice?: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          } | null;
        }>;
      }
    | { __typename?: 'RequestButtonComponent' }
    | {
        __typename?: 'ResortTopHotelsComponent';
        anchor?: string | null;
        backgroundColor?: string | null;
        mainText?: string | null;
        topHotels: Array<{
          __typename?: 'ProductRecommendationItem';
          giata: string;
          name: string;
          adults: number;
          children: number;
          hpRating?: number | null;
          taRating?: number | null;
          taReviews?: number | null;
          type: Types.ProductType;
          distanceInfo?: string | null;
          duration: number;
          disrupter?: string | null;
          departureDate: string;
          returnDate: string;
          fromTo?: string | null;
          productClass: Types.ProductClass;
          mealType: string;
          offerId: string;
          inWishlist: boolean;
          transferIncluded?: boolean | null;
          image: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          geoBreadcrumbs: Array<{
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          }>;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
          featureSummary: Array<{
            __typename?: 'ProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'ProductFeature';
              id: string;
              name: string;
              enabled?: boolean | null;
            }>;
          }>;
          price: { __typename?: 'Price'; amount: string; currency: string };
          wishlistItem?: {
            __typename?: 'WishlistItem';
            id: string;
            inWishlist: boolean;
          } | null;
          transferPrice?: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          } | null;
        }>;
      }
    | { __typename?: 'SrlButtonPlaceholderComponent' }
    | {
        __typename?: 'SrlMapPinsComponent';
        anchor?: string | null;
        mainText?: string | null;
        backgroundColor?: string | null;
        status: Types.Status;
        message?: string | null;
        cheapestGeo?: {
          __typename?: 'SrlMapGeoPin';
          zoom: number;
          geo: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          };
          price: { __typename?: 'Price'; amount: string; currency: string };
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        } | null;
        cheapestProduct?: {
          __typename?: 'SrlMapProductPin';
          id: string;
          name: string;
          top: boolean;
          zoom: number;
          price: { __typename?: 'Price'; amount: string; currency: string };
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        } | null;
        geoPins: Array<{
          __typename?: 'SrlMapGeoPin';
          zoom: number;
          geo: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          };
          price: { __typename?: 'Price'; amount: string; currency: string };
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        }>;
        productPins: Array<{
          __typename?: 'SrlMapProductPin';
          id: string;
          name: string;
          top: boolean;
          zoom: number;
          price: { __typename?: 'Price'; amount: string; currency: string };
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        }>;
      }
    | { __typename?: 'StaticBlogRecommendation' }
    | { __typename?: 'StaticGeoRecommendation' }
    | { __typename?: 'StaticProductRecommendation' }
    | { __typename?: 'StaticThemeRecommendation' }
    | {
        __typename?: 'TextComponent';
        anchor?: string | null;
        title: string;
        text: string;
        type: string;
        bottomPadding?: string | null;
        topPadding?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'ThemeRecommendation';
        anchor?: string | null;
        name?: string | null;
        mainText?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        items: Array<{
          __typename?: 'ThemeRecommendationItem';
          id: string;
          name: string;
          adults: number;
          children: number;
          duration: number;
          image: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          price: { __typename?: 'Price'; amount: string; currency: string };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
        }>;
      }
    | { __typename?: 'UspBoxesComponent' }
  >;
};

export type GeoRecommendationComponentFragment = {
  __typename?: 'GeoRecommendation';
  anchor?: string | null;
  name?: string | null;
  mainText?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  backgroundImage?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  items: Array<{
    __typename?: 'GeoRecommendationItem';
    count: number;
    country?: string | null;
    adults: number;
    children: number;
    averageTemp?: string | null;
    duration: number;
    disrupter?: string | null;
    flightDuration?: string | null;
    mapZoom: number;
    image: {
      __typename?: 'Image';
      alt: string;
      desaturated?: boolean | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    moreInfo?: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    } | null;
    geoObject: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    };
    perPersonPrice: { __typename?: 'Price'; amount: string; currency: string };
    coordinates: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
  }>;
};

export type ThemeRecommendationComponentFragment = {
  __typename?: 'ThemeRecommendation';
  anchor?: string | null;
  name?: string | null;
  mainText?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  backgroundImage?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  items: Array<{
    __typename?: 'ThemeRecommendationItem';
    id: string;
    name: string;
    adults: number;
    children: number;
    duration: number;
    image: {
      __typename?: 'Image';
      alt: string;
      desaturated?: boolean | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    price: { __typename?: 'Price'; amount: string; currency: string };
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
  }>;
};

export type MediaGalleryFragment = {
  __typename?: 'MediaGallery';
  anchor?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  backgroundImage?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  mediaItems: Array<
    | {
        __typename?: 'ExternalMediaItem';
        resource: string;
        title: string;
        thumbnail: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'ImageMediaItem';
        title: string;
        thumbnail: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        image: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'MusicMediaItem';
        resource: string;
        title: string;
        thumbnail: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'PdfMediaItem';
        resource: string;
        title: string;
        thumbnail: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'VideoMediaItem';
        resource: string;
        title: string;
        thumbnail: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
  >;
};

export type ProductRecommendationComponentFragment = {
  __typename?: 'ProductRecommendation';
  anchor?: string | null;
  name?: string | null;
  mainText?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  backgroundImage?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  items: Array<{
    __typename?: 'ProductRecommendationItem';
    giata: string;
    name: string;
    adults: number;
    children: number;
    hpRating?: number | null;
    taRating?: number | null;
    taReviews?: number | null;
    type: Types.ProductType;
    distanceInfo?: string | null;
    duration: number;
    disrupter?: string | null;
    departureDate: string;
    returnDate: string;
    fromTo?: string | null;
    productClass: Types.ProductClass;
    mealType: string;
    offerId: string;
    inWishlist: boolean;
    transferIncluded?: boolean | null;
    image: {
      __typename?: 'Image';
      alt: string;
      desaturated?: boolean | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    geoBreadcrumbs: Array<{
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    }>;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    featureSummary: Array<{
      __typename?: 'ProductFeatureGroup';
      id: string;
      name: string;
      features: Array<{
        __typename?: 'ProductFeature';
        id: string;
        name: string;
        enabled?: boolean | null;
      }>;
    }>;
    price: { __typename?: 'Price'; amount: string; currency: string };
    wishlistItem?: {
      __typename?: 'WishlistItem';
      id: string;
      inWishlist: boolean;
    } | null;
    transferPrice?: {
      __typename?: 'Price';
      amount: string;
      currency: string;
    } | null;
  }>;
};

export type MarketingRecommendationComponentFragment = {
  __typename?: 'MarketingRecommendation';
  anchor?: string | null;
  name?: string | null;
  mainText?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  backgroundImage?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  items: Array<{
    __typename?: 'MarketingRecommendationItem';
    title: string;
    subtitle: string;
    link:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
    preview:
      | {
          __typename?: 'ExternalMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'ImageMediaItem';
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'MusicMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'PdfMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'VideoMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        };
  }>;
};

export type BlogArticleRecommendationFragment = {
  __typename?: 'BlogArticleRecommendation';
  anchor?: string | null;
  name?: string | null;
  mainText?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  backgroundImage?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  items: Array<{
    __typename?: 'BlogArticleRecommendationItem';
    date: string;
    tag: string;
    text: string;
    title: string;
    image: {
      __typename?: 'Image';
      alt: string;
      desaturated?: boolean | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    link:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
  }>;
};

export type Bd4TravelRecommendationGroupFragment = {
  __typename?: 'BD4TravelRecommendationGroup';
  anchor?: string | null;
  name?: string | null;
  mainText?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  backgroundImage?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  items: Array<{
    __typename?: 'BD4TravelRecommendation';
    adults: number;
    children: number;
    departureDate: string;
    returnDate: string;
    disrupter?: string | null;
    distanceInfo?: string | null;
    duration: number;
    fromTo?: string | null;
    giata: string;
    hpRating?: number | null;
    mapZoom: number;
    name: string;
    mealType: string;
    productClass: Types.ProductClass;
    travelType?: string | null;
    type: Types.ProductType;
    offerId: string;
    inWishlist: boolean;
    transferIncluded?: boolean | null;
    taRating?: number | null;
    taReviews?: number | null;
    coordinates: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
    featureSummary: Array<{
      __typename?: 'ProductFeatureGroup';
      id: string;
      name: string;
      features: Array<{
        __typename?: 'ProductFeature';
        id: string;
        name: string;
        enabled?: boolean | null;
      }>;
    }>;
    geoBreadcrumbs: Array<{
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    }>;
    image: {
      __typename?: 'Image';
      alt: string;
      desaturated?: boolean | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    price: { __typename?: 'Price'; amount: string; currency: string };
    wishlistItem?: {
      __typename?: 'WishlistItem';
      id: string;
      inWishlist: boolean;
    } | null;
    transferPrice?: {
      __typename?: 'Price';
      amount: string;
      currency: string;
    } | null;
  }>;
  tracking?: {
    __typename?: 'BD4TravelRecommendationTracking';
    productCount?: number | null;
    productIds?: Array<string> | null;
    ptoken?: string | null;
    status: Types.Bd4TravelRecommendationStatus;
    info?: {
      __typename?: 'BD4TravelRecommendationInfo';
      modelId: string;
      placementId: string;
      strategy: string;
    } | null;
  } | null;
};

export type FlightWithPriceRecommendationFragment = {
  __typename?: 'FlightWithPriceRecommendation';
  anchor?: string | null;
  name?: string | null;
  mainText?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  backgroundImage?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  items: Array<{
    __typename?: 'FlightRecommendationWithPriceItem';
    title: string;
    amount: string;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    media:
      | {
          __typename?: 'ExternalMediaItem';
          resource: string;
          title: string;
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'ImageMediaItem';
          title: string;
          image: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'MusicMediaItem';
          resource: string;
          title: string;
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'PdfMediaItem';
          resource: string;
          title: string;
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'VideoMediaItem';
          resource: string;
          title: string;
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        };
    offer: {
      __typename?: 'FlightOffer';
      offerId: string;
      waitingScreenImage: string;
      pricePerPerson: {
        __typename?: 'Price';
        amount: string;
        currency: string;
      };
      totalPrice: { __typename?: 'Price'; amount: string; currency: string };
      forwardFlight: {
        __typename?: 'Flight';
        departureTime: any;
        arrivalTime: any;
        duration: string;
        arrivalDate: any;
        departureDate: any;
        overnight: boolean;
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        segments: Array<{
          __typename?: 'FlightSegment';
          airportWaiting?: string | null;
          arrivalDate: any;
          arrivalTime: any;
          departureDate: any;
          departureTime: any;
          flightClass: Types.FlightClass;
          flightNumber: string;
          segmentDuration: string;
          airline: {
            __typename?: 'Airline';
            airlineLogo: string;
            iata: string;
            name: string;
          };
          arrival: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          };
          departure: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          };
        }>;
      };
      returnFlight?: {
        __typename?: 'Flight';
        departureTime: any;
        arrivalTime: any;
        duration: string;
        arrivalDate: any;
        departureDate: any;
        overnight: boolean;
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        segments: Array<{
          __typename?: 'FlightSegment';
          airportWaiting?: string | null;
          arrivalDate: any;
          arrivalTime: any;
          departureDate: any;
          departureTime: any;
          flightClass: Types.FlightClass;
          flightNumber: string;
          segmentDuration: string;
          airline: {
            __typename?: 'Airline';
            airlineLogo: string;
            iata: string;
            name: string;
          };
          arrival: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          };
          departure: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          };
        }>;
      } | null;
    };
  }>;
};

export type FlightWithoutPriceRecommendationFragment = {
  __typename?: 'FlightWithoutPriceRecommendation';
  anchor?: string | null;
  name?: string | null;
  mainText?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  backgroundImage?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  items: Array<{
    __typename?: 'FlightRecommendationWithoutPriceItem';
    title: string;
    subtitle: string;
    link:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
    price: { __typename?: 'Price'; amount: string; currency: string };
    media:
      | {
          __typename?: 'ExternalMediaItem';
          resource: string;
          title: string;
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'ImageMediaItem';
          title: string;
          image: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'MusicMediaItem';
          resource: string;
          title: string;
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'PdfMediaItem';
          resource: string;
          title: string;
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'VideoMediaItem';
          resource: string;
          title: string;
          preview: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        };
  }>;
};

export type FaqFragment = {
  __typename?: 'FaqComponent';
  items: Array<{
    __typename?: 'TextComponent';
    title: string;
    text: string;
    bottomPadding?: string | null;
    topPadding?: string | null;
  }>;
};

export type AccordionComponentFragment = {
  __typename?: 'AccordionComponent';
  anchor?: string | null;
  bottomBackgroundColor?: string | null;
  bottomFontColor?: string | null;
  bottomMainText?: string | null;
  bottomMainTitle?: string | null;
  topBackgroundColor?: string | null;
  topFontColor?: string | null;
  topMainText?: string | null;
  topMainTitle?: string | null;
  components: Array<
    | { __typename?: 'AccordionComponent' }
    | { __typename?: 'AgencyContactComponent' }
    | { __typename?: 'AgencyRecommendation' }
    | { __typename?: 'BD4TravelRecommendationGroup' }
    | { __typename?: 'BenefitsComponent' }
    | { __typename?: 'BlogArticleRecommendation' }
    | { __typename?: 'BrandsComponent' }
    | { __typename?: 'ClimateComponent' }
    | { __typename?: 'ContactModuleComponent' }
    | { __typename?: 'EmployeesComponent' }
    | {
        __typename?: 'FaqComponent';
        items: Array<{
          __typename?: 'TextComponent';
          fontColor?: string | null;
          backgroundColor?: string | null;
          title: string;
          text: string;
          bottomPadding?: string | null;
          topPadding?: string | null;
        }>;
      }
    | { __typename?: 'FlightWithoutPriceRecommendation' }
    | { __typename?: 'FlightWithPriceRecommendation' }
    | { __typename?: 'GeoChildrenComponent' }
    | { __typename?: 'GeoGalleryComponent' }
    | { __typename?: 'GeoRecommendation' }
    | { __typename?: 'HeroMediaGallery' }
    | { __typename?: 'ImageTextComponent' }
    | { __typename?: 'LinkListComponent' }
    | { __typename?: 'MarketingRecommendation' }
    | { __typename?: 'MarketingTeaserComponent' }
    | { __typename?: 'MediaGallery' }
    | { __typename?: 'NewsArticlesComponent' }
    | { __typename?: 'NewsletterSignupComponent' }
    | { __typename?: 'OfferButtonComponent' }
    | { __typename?: 'OffersMapPlaceholderComponent' }
    | { __typename?: 'ProductGalleryWithMapComponent' }
    | { __typename?: 'ProductRecommendation' }
    | { __typename?: 'RequestButtonComponent' }
    | { __typename?: 'ResortTopHotelsComponent' }
    | { __typename?: 'SrlButtonPlaceholderComponent' }
    | { __typename?: 'SrlMapPinsComponent' }
    | { __typename?: 'StaticBlogRecommendation' }
    | { __typename?: 'StaticGeoRecommendation' }
    | { __typename?: 'StaticProductRecommendation' }
    | { __typename?: 'StaticThemeRecommendation' }
    | { __typename?: 'TextComponent' }
    | { __typename?: 'ThemeRecommendation' }
    | { __typename?: 'UspBoxesComponent' }
  >;
};

export type OfferButtonComponentFragment = {
  __typename?: 'OfferButtonComponent';
  anchor?: string | null;
  caption: string;
  backgroundColor?: string | null;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
};

export type SrlMapPinsComponentFragment = {
  __typename?: 'SrlMapPinsComponent';
  anchor?: string | null;
  mainText?: string | null;
  backgroundColor?: string | null;
  status: Types.Status;
  message?: string | null;
  cheapestGeo?: {
    __typename?: 'SrlMapGeoPin';
    zoom: number;
    geo: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    };
    price: { __typename?: 'Price'; amount: string; currency: string };
    coordinates: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
  } | null;
  cheapestProduct?: {
    __typename?: 'SrlMapProductPin';
    id: string;
    name: string;
    top: boolean;
    zoom: number;
    price: { __typename?: 'Price'; amount: string; currency: string };
    coordinates: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
  } | null;
  geoPins: Array<{
    __typename?: 'SrlMapGeoPin';
    zoom: number;
    geo: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    };
    price: { __typename?: 'Price'; amount: string; currency: string };
    coordinates: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
  }>;
  productPins: Array<{
    __typename?: 'SrlMapProductPin';
    id: string;
    name: string;
    top: boolean;
    zoom: number;
    price: { __typename?: 'Price'; amount: string; currency: string };
    coordinates: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
  }>;
};

export type ClimateComponentFragment = {
  __typename?: 'ClimateComponent';
  anchor?: string | null;
  mainText?: string | null;
  sunHoursPerYear: string;
  backgroundColor?: string | null;
  charts: Array<{
    __typename?: 'ClimateChart';
    type: Types.ClimateChartType;
    title: string;
    entries: Array<{
      __typename?: 'ClimateChartEntry';
      month: string;
      value: number;
    }>;
  }>;
};

export type GeoChildrenComponentFragment = {
  __typename?: 'GeoChildrenComponent';
  anchor?: string | null;
  backgroundColor?: string | null;
  mainText?: string | null;
  children: Array<{
    __typename?: 'GeoChildComponent';
    title: string;
    image: {
      __typename?: 'Image';
      alt: string;
      desaturated?: boolean | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
  }>;
};

export type ResortTopHotelsComponentFragment = {
  __typename?: 'ResortTopHotelsComponent';
  anchor?: string | null;
  backgroundColor?: string | null;
  mainText?: string | null;
  topHotels: Array<{
    __typename?: 'ProductRecommendationItem';
    giata: string;
    name: string;
    adults: number;
    children: number;
    hpRating?: number | null;
    taRating?: number | null;
    taReviews?: number | null;
    type: Types.ProductType;
    distanceInfo?: string | null;
    duration: number;
    disrupter?: string | null;
    departureDate: string;
    returnDate: string;
    fromTo?: string | null;
    productClass: Types.ProductClass;
    mealType: string;
    offerId: string;
    inWishlist: boolean;
    transferIncluded?: boolean | null;
    image: {
      __typename?: 'Image';
      alt: string;
      desaturated?: boolean | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    geoBreadcrumbs: Array<{
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    }>;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    featureSummary: Array<{
      __typename?: 'ProductFeatureGroup';
      id: string;
      name: string;
      features: Array<{
        __typename?: 'ProductFeature';
        id: string;
        name: string;
        enabled?: boolean | null;
      }>;
    }>;
    price: { __typename?: 'Price'; amount: string; currency: string };
    wishlistItem?: {
      __typename?: 'WishlistItem';
      id: string;
      inWishlist: boolean;
    } | null;
    transferPrice?: {
      __typename?: 'Price';
      amount: string;
      currency: string;
    } | null;
  }>;
};

export type GeoGalleryComponentFragment = {
  __typename?: 'GeoGalleryComponent';
  anchor?: string | null;
  backgroundColor?: string | null;
  mainText?: string | null;
  mediaItems: Array<
    | {
        __typename?: 'ExternalMediaItem';
        resource: string;
        title: string;
        thumbnail: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'ImageMediaItem';
        title: string;
        thumbnail: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        image: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'MusicMediaItem';
        resource: string;
        title: string;
        thumbnail: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'PdfMediaItem';
        resource: string;
        title: string;
        thumbnail: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'VideoMediaItem';
        resource: string;
        title: string;
        thumbnail: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
  >;
};

export type MarketingTeaserComponentFragment = {
  __typename?: 'MarketingTeaserComponent';
  anchor?: string | null;
  backgroundColorAlignment?: string | null;
  backgroundColor?: string | null;
  backgroundTheme?: string | null;
  bottomPadding?: string | null;
  topPadding?: string | null;
  buttonTitle: string;
  mainText?: string | null;
  title: string;
  buttonLink:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      };
  teaserImage?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  logoImage?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
};

export type ImageTextComponentFragment = {
  __typename?: 'ImageTextComponent';
  text: string;
  imagePlacement: Types.ImagePlacement;
  image?: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
};

export const GeoRecommendationComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'geoRecommendationComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeoRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1536' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1084' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '768' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '542' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1920' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '704' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '3840' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1408' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geoRecommendation' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...GeoRecommendationFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ThemeRecommendationComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'themeRecommendationComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ThemeRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1536' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1084' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '768' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '542' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1920' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '704' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '3840' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1408' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'themeRecommendation' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...ThemeRecommendationFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const MediaGalleryFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'mediaGallery' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MediaGallery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1536' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '488' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '768' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '244' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1920' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '709' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '3840' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '709' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MediaGalleryItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...MediaGalleryItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ProductRecommendationComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productRecommendationComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1536' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1084' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '768' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '542' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1920' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '704' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '3840' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1408' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productRecommendation' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...ProductRecommendationFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const MarketingRecommendationComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'marketingRecommendationComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1536' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1084' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '768' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '542' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1920' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '704' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '3840' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1408' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'marketingRecommendation' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...MarketingRecommendationFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const BlogArticleRecommendationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'blogArticleRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BlogArticleRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1536' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1084' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '768' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '542' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1920' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '704' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '3840' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1408' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'blogArticle' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...BlogArticleFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const Bd4TravelRecommendationGroupFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BD4TravelRecommendationGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BD4TravelRecommendationGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1536' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1084' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '768' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '542' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1920' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '704' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '3840' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1408' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BD4TravelRecommendation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tracking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BD4TravelRecommendationTracking',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...Bd4TravelRecommendationFragmentDoc.definitions,
    ...Bd4TravelRecommendationTrackingFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const FlightWithPriceRecommendationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'flightWithPriceRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlightWithPriceRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1536' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1084' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '768' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '542' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1920' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '704' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '3840' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1408' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FlightRecommendationWithPrice',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...FlightRecommendationWithPriceFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const FlightWithoutPriceRecommendationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'flightWithoutPriceRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlightWithoutPriceRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1536' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1084' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '768' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '542' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1920' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '704' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '3840' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1408' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FlightRecommendationWithoutPrice',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...FlightRecommendationWithoutPriceFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const FaqFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'faq' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'textComponent' },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextComponentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const AccordionComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'accordionComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AccordionComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bottomBackgroundColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomFontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topBackgroundColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'topFontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'topMainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'topMainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'components' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FaqComponent' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'textComponent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fontColor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'backgroundColor' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextComponentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const OfferButtonComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'offerButtonComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OfferButtonComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const SrlMapPinsComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlMapPinsComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlMapPinsComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cheapestGeo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'srlMapGeoPin' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cheapestProduct' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'srlMapProductPin' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoPins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'srlMapGeoPin' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productPins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'srlMapProductPin' },
                },
              ],
            },
          },
        ],
      },
    },
    ...SrlMapGeoPinFragmentDoc.definitions,
    ...SrlMapProductPinFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ClimateComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'climateComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClimateComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sunHoursPerYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'charts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export const GeoChildrenComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'geoChildrenComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeoChildrenComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '2x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '728' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '650' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '364' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '325' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '2x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '740' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '740' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '370' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '370' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'internalLinkFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ResortTopHotelsComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'resortTopHotelsComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResortTopHotelsComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topHotels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productRecommendation' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProductRecommendationFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const GeoGalleryComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'geoGalleryComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeoGalleryComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MediaGalleryItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...MediaGalleryItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const MarketingTeaserComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'marketingTeaserComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingTeaserComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColorAlignment' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundTheme' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomPadding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'topPadding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttonLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teaserImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1170' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '782' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '320' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '120' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LinkFragmentFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ImageTextComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'imageTextComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ImageTextComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '364' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '325' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '728' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '650' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '370' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '330' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '740' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '659' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const GetDynamicComponentsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDynamicComponents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uri' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchQuery' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gss' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GlobalSearchStateInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'components' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestContext' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pageType' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'currentUri' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'uri' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchQuery' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchQuery' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gss' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gss' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'typedTextComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geoRecommendationComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'themeRecommendationComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'mediaGallery' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'productRecommendationComponent',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'marketingRecommendationComponent',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'blogArticleRecommendation' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BD4TravelRecommendationGroup' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'flightWithoutPriceRecommendation',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'flightWithPriceRecommendation',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'newsletterSignupComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'faq' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'accordionComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'offerButtonComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'srlMapPinsComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'climateComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geoChildrenComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'resortTopHotelsComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geoGalleryComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'marketingTeaserComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'imageTextComponent' },
                },
              ],
            },
          },
        ],
      },
    },
    ...TypedTextComponentFragmentDoc.definitions,
    ...GeoRecommendationComponentFragmentDoc.definitions,
    ...ThemeRecommendationComponentFragmentDoc.definitions,
    ...MediaGalleryFragmentDoc.definitions,
    ...ProductRecommendationComponentFragmentDoc.definitions,
    ...MarketingRecommendationComponentFragmentDoc.definitions,
    ...BlogArticleRecommendationFragmentDoc.definitions,
    ...Bd4TravelRecommendationGroupFragmentDoc.definitions,
    ...FlightWithoutPriceRecommendationFragmentDoc.definitions,
    ...FlightWithPriceRecommendationFragmentDoc.definitions,
    ...NewsletterSignupComponentFragmentDoc.definitions,
    ...FaqFragmentDoc.definitions,
    ...AccordionComponentFragmentDoc.definitions,
    ...OfferButtonComponentFragmentDoc.definitions,
    ...SrlMapPinsComponentFragmentDoc.definitions,
    ...ClimateComponentFragmentDoc.definitions,
    ...GeoChildrenComponentFragmentDoc.definitions,
    ...ResortTopHotelsComponentFragmentDoc.definitions,
    ...GeoGalleryComponentFragmentDoc.definitions,
    ...MarketingTeaserComponentFragmentDoc.definitions,
    ...ImageTextComponentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetDynamicComponentsQuery__
 *
 * To run a query within a React component, call `useGetDynamicComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicComponentsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      uri: // value for 'uri'
 *      pageType: // value for 'pageType'
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *      gss: // value for 'gss'
 *   },
 * });
 */
export function useGetDynamicComponentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDynamicComponentsQuery,
    GetDynamicComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetDynamicComponentsQuery,
    GetDynamicComponentsQueryVariables
  >(GetDynamicComponentsDocument, options);
}
export function useGetDynamicComponentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDynamicComponentsQuery,
    GetDynamicComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetDynamicComponentsQuery,
    GetDynamicComponentsQueryVariables
  >(GetDynamicComponentsDocument, options);
}
export type GetDynamicComponentsQueryHookResult = ReturnType<
  typeof useGetDynamicComponentsQuery
>;
export type GetDynamicComponentsLazyQueryHookResult = ReturnType<
  typeof useGetDynamicComponentsLazyQuery
>;
export type GetDynamicComponentsQueryResult = Apollo.QueryResult<
  GetDynamicComponentsQuery,
  GetDynamicComponentsQueryVariables
>;
