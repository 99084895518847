import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { IImageProps, Image } from '@hotelplan/components.common.image';

export const DynamicComponentWrapper = styled.div<{
  withoutContainer: boolean;
}>(({ withoutContainer }) =>
  sx2CssThemeFn({
    maxWidth: withoutContainer ? '100%' : '1218px',
    width: '100%',
    mx: 'auto',
    px: withoutContainer ? 0 : [0, 4],
    zIndex: 2,
    '.text-block,.marketing-teaser': {
      pt: [null, 3],
      pb: ['40px', '75px'],
      px: [4, 0],
      '&.none-padding-bottom': {
        paddingBottom: 0,
      },
      '&.none-padding-top': {
        paddingTop: 0,
      },
      '&.small-padding-bottom': {
        paddingBottom: 3,
      },
      '&.small-padding-top': {
        paddingTop: 3,
      },
      '&.large-padding-bottom': {
        paddingBottom: ['40px', '75px'],
      },
      '&.large-padding-top': {
        paddingTop: ['40px', '75px'],
      },
    },
  })
);

export const DynamicComponentStyles = styled.div<{
  backgroundColor?: string;
  fontColor?: string;
}>(({ backgroundColor, fontColor }) =>
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor,
    color: fontColor,
  })
);

export const DynamicComponentBackgroundImage = styled(Image).attrs({
  lazy: false,
  decoding: `sync`,
  loading: 'eager',
  alt: 'background image',
} as Partial<IImageProps>)(
  sx2CssThemeFn({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'block',
    maxHeight: '100%',
    zIndex: 1,
  })
);
