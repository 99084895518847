import { useMemo } from 'react';
import { ICanonicalTravelDestination } from '@hotelplan/components.common.travel-destination';
import { useSearchState } from '@hotelplan/libs.search-state';
import { IFiltersFormState } from 'components/domain/filters/Filters.types';
import { mapSearchControlToCriteria } from 'components/domain/searchControl/SearchControl.mappers';
import { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/srl/SRL.mappers';
import type { ISRLState } from 'components/domain/srl/SRL.types';
import { mapFormTravelDestinationToSearchCriteriaInputTravelDestination } from 'components/domain/travel-destination/TravelDestination.mappers';
import {
  GetSrlMapPinsQuery,
  useGetSrlMapPinsQuery,
} from 'graphql/srlMap/GetSRLMapPins.generated';
import { Status } from 'graphql/types/resolversTypes';
import { mapSrlMapDataToMapPins } from './SRLMap.mappers';

type TData = GetSrlMapPinsQuery;

interface ISRLMapItemsConfig {
  destinations?: ICanonicalTravelDestination[] | null;
  onCompleted?: (data: TData) => void;
  filters?: IFiltersFormState;
}

export default function useSRLMapItems(config: ISRLMapItemsConfig) {
  const { destinations, onCompleted, filters: forcedFilters } = config;

  const {
    loading,
    state: { searchControl, filters, subGeoFilters },
  } = useSearchState<ISRLState>();

  // Replace search control destination by selected geo object on map
  const searchControlInput = useMemo(() => {
    const input = mapSearchControlToCriteria(
      searchControl as ISearchControlFormState
    );

    if (destinations?.length) {
      input.travelDestinations = mapFormTravelDestinationToSearchCriteriaInputTravelDestination(
        {
          destinations,
        }
      );
    }

    return input;
  }, [searchControl, destinations]);

  const { data, loading: queryLoading, error } = useGetSrlMapPinsQuery({
    variables: {
      searchControl: searchControlInput,
      filters: mapSrlFilterValuesToSrlFilterCriteriaInput(
        typeof forcedFilters !== 'undefined' ? forcedFilters : filters
      ),
      subGeoFilters: subGeoFilters,
    },
    skip: loading,
    notifyOnNetworkStatusChange: true,
    onCompleted,
  });

  const mapItems = useMemo(() => {
    return mapSrlMapDataToMapPins(data);
  }, [data]);

  return {
    loading: loading || queryLoading,
    mapItems,
    error: !!error || data?.srl?.search?.mapPins?.status === Status.Failure,
  };
}
