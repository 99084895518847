import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { FlightPartitionFragmentDoc } from './FlightPartition.generated';
export type FlightSearchControlFragment = {
  __typename?: 'FlightSearchControlComponent';
  travelType: Types.TravelType;
  flightType: Types.FlightType;
  travellers: {
    __typename?: 'Travellers';
    adults: number;
    childrenDobs?: Array<string> | null;
  };
  flightPartitions?: Array<{
    __typename?: 'FlightPartition';
    flightClass: Types.FlightClass;
    departureAirport?: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    } | null;
    arrivalAirport?: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    } | null;
    travelPeriod: {
      __typename?: 'ExactTravelPeriod';
      returnDate: string;
      departureDate: string;
    };
  }> | null;
};

export const FlightSearchControlFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightSearchControl' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlightSearchControlComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flightType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travellers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childrenDobs' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightPartitions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'flightPartition' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FlightPartitionFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
