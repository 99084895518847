import { useTranslation } from 'next-i18next';
import React from 'react';
import { MapLoader } from '@hotelplan/components.common.map';
import { TravelType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { track } from '@hotelplan/libs.tracking-events';
import { PageType } from '@hotelplan/platform-graphql-api';
import { DestinationStackProvider } from 'components/domain/destinationStack/DestinationStackContext';
import { mapMainFilterComponentValuesToFiltersFormState } from 'components/domain/filters/Filters.mappers';
import MapAccessButton from 'components/domain/mapAccess/MapAccessButton';
import MapAccessLayout from 'components/domain/mapAccess/MapAccessLayout';
import SRLMapAccessPreview from 'components/domain/srl/SRLMapAccessPreview';
import SRLMapAccessStateContext from 'components/domain/srl/SRLMapAccessStateContext';
import SRLMapAccessForm from 'components/domain/srlMap/SRLMapAccessForm';
import SRLMapModal from 'components/domain/srlMap/SRLMapModal';
import { MainFilterComponentValuesFragment } from 'graphql/searchFilter/MainFilterComponentValues.generated';
import { useGetSrlDefaultFilterValuesQuery } from 'graphql/srl/GetSRLDefaultFilterValues.generated';
import { useGetThemeContentQuery } from 'graphql/theme/GetThemeContent.generated';
import useThemeSearchControlState from './useThemeSearchControlState';

const trackMapOpenClick = (language: string) => {
  track({
    event: `enlarge_map`,
    action: `enlarge`,
    label: `Karte öffnen auf Theme Seite`,
    category: undefined,
    component: 'map',
    position: 'maincontent',
    language,
    _clear: true,
  });
};

const ThemeMapAccess: React.FC = () => {
  const { mobile } = useDeviceType();
  const { id } = useObjectId();
  const pageType = usePageType();
  const [, { language }] = useTranslation();

  const [show, open, close] = useToggleState(false);

  const handleMapOpen = () => {
    open();

    if (
      pageType === PageType.ThemeObject ||
      pageType === PageType.ThemeOverview
    ) {
      trackMapOpenClick(language);
    }
  };

  const {
    data: contentData,
    loading: contentLoading,
  } = useGetThemeContentQuery({
    variables: {
      id,
    },
  });

  const showMapAccess: boolean | undefined =
    contentData?.theme.staticContent.mapAccess;

  const {
    searchControlState,
    loading: searchValuesLoading,
  } = useThemeSearchControlState(id, { skip: showMapAccess === false });

  const {
    data: filtersData,
    loading: filterValuesLoading,
  } = useGetSrlDefaultFilterValuesQuery({
    ssr: false,
    skip: showMapAccess === false,
  });

  const anyLoading =
    searchValuesLoading || filterValuesLoading || contentLoading;

  if (showMapAccess === false) return null;

  if (typeof contentData === 'undefined' || anyLoading)
    return mobile ? (
      <>{'MapAccessButtonSkeleton' as const}</>
    ) : (
      <MapAccessLayout>
        <MapLoader />
      </MapAccessLayout>
    );

  const travelType = searchControlState?.travelType as TravelType;

  const canonicalDestinations =
    searchControlState?.type === 'HOTEL'
      ? searchControlState.travelDestination?.destinations
      : undefined;

  const filters = mapMainFilterComponentValuesToFiltersFormState(
    (travelType === TravelType.Hotel
      ? filtersData?.srl.hotelControls
      : filtersData?.srl.packageControls
    )?.mainFilter as MainFilterComponentValuesFragment
  );

  if (!canonicalDestinations) return null;

  return (
    <MapAccessLayout>
      <DestinationStackProvider destinations={canonicalDestinations}>
        <SRLMapAccessStateContext
          searchControl={searchControlState}
          filter={filters}
        >
          {mobile ? (
            <MapAccessButton onOpen={handleMapOpen} />
          ) : (
            <SRLMapAccessPreview
              destinations={canonicalDestinations}
              onOpen={handleMapOpen}
              filters={filters}
            />
          )}
          <SRLMapModal show={show} onClose={close}>
            <SRLMapAccessForm />
          </SRLMapModal>
        </SRLMapAccessStateContext>
      </DestinationStackProvider>
    </MapAccessLayout>
  );
};

export default ThemeMapAccess;
