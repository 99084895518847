import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import {
  PinCluster,
  MapPinView,
  mapCoordinatesToLatLng,
  MapClusterer,
} from '@hotelplan/components.common.map-pin';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { SrlMapGeoPinFragment } from 'graphql/srlMap/SRLMapFragments.generated';
import SRLMapGeoPin from './SRLMapGeoPin';

const PinContent = styled.div(({ theme: { space } }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: space[1],
  paddingRight: space[1],
}));

const PlanetIcon = styled(Icon).attrs({ name: `planet`, size: 'lg' })(
  ({ theme: { space } }) => ({
    display: 'inline-block',
    paddingRight: space[2],
  })
);

const PinText = styled.span(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn(FONT_SIZE.MEDIUM_BOLD)
);

function renderPinContent(cluster?: PinCluster): React.ReactNode {
  return (
    <PinContent>
      <PlanetIcon />
      <PinText>{cluster?.getSize()}</PinText>
    </PinContent>
  );
}

type Geo = SrlMapGeoPinFragment;

interface ISRLMapGeoClusterProps {
  objects: Geo[];
  onClusterClick(objects: Geo[], cluster: PinCluster): void;
  onGeoClick(geo: Geo, pin: MapPinView<Geo>): void;
}

const SRLMapGeoCluster: React.FC<ISRLMapGeoClusterProps> = props => {
  const { objects, onGeoClick, onClusterClick } = props;

  function onClusterClickHandler(cluster: PinCluster): void {
    const pins = cluster.getMarkers() as MapPinView<Geo>[];
    const items = pins?.map(pin => pin.object);
    onClusterClick(items, cluster);
  }

  return (
    <MapClusterer
      renderPinContent={renderPinContent}
      onClusterClick={onClusterClickHandler}
    >
      {objects.map((geo, index) => (
        <SRLMapGeoPin
          key={`${index}-${mapCoordinatesToLatLng(geo.coordinates).toString()}`}
          geo={geo}
          onClick={onGeoClick}
        />
      ))}
    </MapClusterer>
  );
};

export default SRLMapGeoCluster;
