import type { ICanonicalTravelDestination } from '@hotelplan/components.common.travel-destination';
import { TravelDestinationType } from '@hotelplan/graphql.types';
import { CoordinatesFragment } from 'graphql/srlMap/Coordinates.generated';

interface IMapArtifacts {
  coordinates?: CoordinatesFragment;
  mapZoom?: number;
}

export type IDestination = ICanonicalTravelDestination & IMapArtifacts;

export function formatDestinations(
  destinations?: IDestination[] | null
): string | undefined {
  return destinations?.map(destination => destination.canonicalName).join(', ');
}

export function getThemesFromDestinations(
  destinations?: IDestination[]
): IDestination[] {
  return (
    destinations?.filter(
      destination => destination.type === TravelDestinationType.Theme
    ) || []
  );
}
