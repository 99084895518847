import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { CheckboxGroupFilterOptionFragmentDoc } from 'graphql/searchFilter/CheckboxGroupFilterOption.generated';
import { RadiobuttonGroupFilterOptionFragmentDoc } from 'graphql/searchFilter/RadiobuttonGroupFilterOption.generated';
import { SliderFilterFragmentDoc } from 'graphql/searchFilter/SliderFilter.generated';
import { UrlFilterOptionsFragmentDoc } from 'graphql/searchFilter/URLFilterOptions.generated';
export type SrlFilterOptionsFragment = {
  __typename?: 'FilterComponent';
  roomTypes: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  accommodationSize?: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  } | null;
  boardTypes: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  popularFilters?: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  } | null;
  mainFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  childrenFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  beachFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  distanceFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  sportFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  wellnessFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  hotelFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  environmentFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  cultureFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  hpRating: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  taRating: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  maxPrice: {
    __typename?: 'SliderFilterComponent';
    caption: string;
    selected: string;
  };
  departureAirports: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  stopOvers: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  directFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  returnFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  maxFlightDuration: {
    __typename?: 'SliderFilterComponent';
    caption: string;
    selected: string;
  };
  departureWeekdays: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  arrivalWeekdays: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  arrivalAirports: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  minPrice: {
    __typename?: 'SliderFilterComponent';
    caption: string;
    selected: string;
  };
  productCode?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  provider?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  flightProvider?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  refundableFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  directFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  returnFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  flightAirlines: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
};

export const SrlFilterOptionsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SRLFilterOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roomTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accommodationSize' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boardTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'popularFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mainFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childrenFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beachFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distanceFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sportFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wellnessFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotelFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'environmentFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cultureFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hpRating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taRating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SliderFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'departureAirports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOvers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'maxFlightDuration' },
            name: { kind: 'Name', value: 'flightDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SliderFilter' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'URLFilterOptions' },
          },
        ],
      },
    },
    ...CheckboxGroupFilterOptionFragmentDoc.definitions,
    ...RadiobuttonGroupFilterOptionFragmentDoc.definitions,
    ...SliderFilterFragmentDoc.definitions,
    ...UrlFilterOptionsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
