import { useMemo } from 'react';
import { TravelType as BackendTravelType } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import {
  mapMainFilterComponentOptionsToLocalFilterOptions,
  mapMainFilterComponentOptionsToLocalFilterOptionCaptions,
} from 'components/domain/filters/Filters.mappers';
import {
  IFilterOptionsResult,
  IFilterOption,
} from 'components/domain/filters/Filters.types';
import { mapSearchControlToCriteria } from 'components/domain/searchControl/SearchControl.mappers';
import { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/srl/SRL.mappers';
import { MainFilterComponentOptionsFragment } from 'graphql/searchFilter/MainFilterComponentOptions.generated';
import { useGetSrlDynamicOptionsQuery } from 'graphql/srl/GetSRLDynamicOptions.generated';
import { useGetSrlFilterOptionsQuery } from 'graphql/srl/GetSRLFilterOptions.generated';
import type { ISRLState } from './SRL.types';

export default function useSRLFilterOptions(): IFilterOptionsResult {
  const { state, loading: stateLoading } = useSearchState<ISRLState>();
  const { data, loading: optionsLoading } = useGetSrlFilterOptionsQuery({
    ssr: false,
    variables: {
      travelType:
        (state.searchControl?.travelType as BackendTravelType) ||
        BackendTravelType.Package,
    },
  });

  const { data: dynamicFilterOptionsData } = useGetSrlDynamicOptionsQuery({
    ssr: false,
    skip: stateLoading,
    variables: {
      searchControl: mapSearchControlToCriteria(
        state.searchControl as ISearchControlFormState
      ),
      filters: mapSrlFilterValuesToSrlFilterCriteriaInput(state.filters),
      subGeoFilters: state.subGeoFilters,
    },
  });

  const commonFilterOptions = useMemo(
    () =>
      data?.srl.searchControls.mainFilter
        ? mapMainFilterComponentOptionsToLocalFilterOptions(
            data.srl.searchControls
              .mainFilter as MainFilterComponentOptionsFragment
          )
        : null,
    [data]
  );

  const dynamicOptions =
    dynamicFilterOptionsData?.srl.searchControls.mainFilter;

  const flightAirlines: IFilterOption[] =
    dynamicOptions?.flightAirlines.options || [];
  const provider: IFilterOption | undefined = dynamicOptions?.provider
    ? dynamicOptions?.provider
    : undefined;

  const options: IFilterOptionsResult['options'] = commonFilterOptions
    ? {
        ...commonFilterOptions,
        flightAirlines,
        provider,
      }
    : null;
  const optionCaptions: IFilterOptionsResult['optionCaptions'] = data?.srl
    .searchControls.mainFilter
    ? mapMainFilterComponentOptionsToLocalFilterOptionCaptions(
        data.srl.searchControls.mainFilter as MainFilterComponentOptionsFragment
      )
    : null;

  return {
    loading: optionsLoading || stateLoading,
    options,
    optionCaptions,
  };
}
