import React from 'react';
import { useFormContext } from '@hotelplan/components.common.forms';
import { TravelType } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import {
  packageFiltersOrder,
  hotelsFiltersOrder,
  packageFiltersOrderWithPopularFilter,
} from 'components/domain/filters/Filters.constants';
import {
  TFilterOptionOrderName,
  IFiltersFormState,
  IFilterOptions,
  TFilterOptionCaptions,
} from 'components/domain/filters/Filters.types';
import SearchFilterInput from 'components/domain/filters/search-filter-input/SearchFilterInput';
import { SearchFilterInputSkeleton } from 'components/domain/filters/search-filter-input/SearchFilterInput.skeleton';
import SRLFilterCountsLoader from 'components/domain/srl/SRLFilterCountsLoader';
import usePopularFiltersFeatureToggle from 'components/domain/srl/usePopularFilterFeatureToggle';
import useSRLFilterOptions from 'components/domain/srl/useSRLFilterOptions';
import type { ISRLState } from './SRL.types';

const travelTypeToFiltersDict: {
  [key in TravelType]: TFilterOptionOrderName[];
} = {
  [TravelType.Hotel]: hotelsFiltersOrder,
  [TravelType.Package]: packageFiltersOrder,
  [TravelType.Flight]: [],
};

const getFilters = (
  travelType: TravelType,
  isPopularFilterEnabled: boolean
) => {
  return isPopularFilterEnabled && travelType === TravelType.Package
    ? packageFiltersOrderWithPopularFilter
    : travelTypeToFiltersDict[travelType] || [];
};

const SRLSearchFilterField: React.FC = () => {
  const {
    values: filterValues,
    onChange,
    onReset,
  } = useFormContext<IFiltersFormState>();
  const {
    state: { searchControl },
  } = useSearchState<ISRLState>();
  const isPopularFilterEnabled = usePopularFiltersFeatureToggle();

  const { options, optionCaptions, loading } = useSRLFilterOptions();

  if (loading && !options) return <SearchFilterInputSkeleton />;

  return (
    <SearchFilterInput
      filters={getFilters(searchControl?.travelType, isPopularFilterEnabled)}
      onChange={onChange}
      onReset={onReset}
      filterValues={filterValues}
      filterOptions={options as IFilterOptions}
      filterOptionCaptions={optionCaptions as TFilterOptionCaptions}
      filterCountsLoader={SRLFilterCountsLoader}
    />
  );
};

export default SRLSearchFilterField;
