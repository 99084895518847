import noop from 'lodash/noop';
import React, { SetStateAction, useState } from 'react';
import { SrlPrimarySort } from '@hotelplan/graphql.types';
import { useGSSContext } from '@hotelplan/libs.gss';
import { SearchStateContext } from '@hotelplan/libs.search-state';
import type { IFiltersFormState } from 'components/domain/filters/Filters.types';
import type { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import type { ISRLState } from './SRL.types';

interface IMapAccessContext extends React.PropsWithChildren<{}> {
  searchControl?: ISearchControlFormState | null;
  filter?: IFiltersFormState | null;
  subGeoFilters?: string[] | null;
}

const SRLMapAccessStateContext: React.FC<IMapAccessContext> = props => {
  const {
    children,
    searchControl = null,
    filter: comingFilters = null,
    subGeoFilters = null,
  } = props;

  const [filters, setFilters] = useState(comingFilters);
  const { gss } = useGSSContext<ISearchControlFormState>();

  const state: ISRLState = {
    searchControl: {
      ...searchControl,
      ...gss,
    },
    filters,
    subGeoFilters,
    primarySort: SrlPrimarySort.None,
    groupSorting: undefined,
    productSorting: undefined,
    forceSingleView: undefined,
  };

  function setState(nextState: SetStateAction<ISRLState>) {
    const { filters: nextFilters } =
      typeof nextState === 'function' ? nextState(state) : nextState;
    setFilters(nextFilters);
  }

  return (
    <SearchStateContext.Provider
      value={{
        state,
        loading: false,
        setState,
        subscribe: () => noop,
        searchQuery: '',
        updateSearchQuery: noop,
      }}
    >
      {children}
    </SearchStateContext.Provider>
  );
};

export default SRLMapAccessStateContext;
