import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { Bd4TravelRecommendationInfoFragmentDoc } from './BD4TravelRecommendationInfo.generated';
export type Bd4TravelRecommendationTrackingFragment = {
  __typename?: 'BD4TravelRecommendationTracking';
  productCount?: number | null;
  productIds?: Array<string> | null;
  ptoken?: string | null;
  status: Types.Bd4TravelRecommendationStatus;
  info?: {
    __typename?: 'BD4TravelRecommendationInfo';
    modelId: string;
    placementId: string;
    strategy: string;
  } | null;
};

export const Bd4TravelRecommendationTrackingFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BD4TravelRecommendationTracking' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BD4TravelRecommendationTracking' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'info' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BD4TravelRecommendationInfo' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ptoken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    ...Bd4TravelRecommendationInfoFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
