import dynamic from 'next/dynamic';
import React from 'react';
import styled from 'styled-components';
import {
  AccordionMode,
  Accordion,
  AccordionItem,
} from '@hotelplan/components.common.accordion';
import { MediaGalleryLazy } from '@hotelplan/components.common.media-gallery';
import { RawTextBlock } from '@hotelplan/components.common.raw-text-block';
import { TextBlock } from '@hotelplan/components.common.text-block';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { AccordionWrapper } from 'components/domain/dynamic-accordion/DynamicAccordion.styles';
import {
  getBottomPadding,
  getTopPadding,
} from 'components/domain/dynamic-components/DynamicComponents.utils';
import RecommendationGroup from 'components/domain/recommendationGroup/RecommendationGroup';
import ThemeMapAccessBlock from 'components/domain/theme/ThemeMapAccessBlock';
import ThemeSRLButtonBlock from 'components/domain/theme/ThemeSRLButtonBlock';
import type {
  Bd4TravelRecommendationGroupFragment,
  BlogArticleRecommendationFragment,
  ClimateComponentFragment,
  FaqFragment,
  FlightWithoutPriceRecommendationFragment,
  FlightWithPriceRecommendationFragment,
  GeoChildrenComponentFragment,
  GeoGalleryComponentFragment,
  GeoRecommendationComponentFragment,
  MarketingRecommendationComponentFragment,
  MediaGalleryFragment,
  OfferButtonComponentFragment,
  ProductRecommendationComponentFragment,
  ResortTopHotelsComponentFragment,
  SrlMapPinsComponentFragment,
  ThemeRecommendationComponentFragment,
  MarketingTeaserComponentFragment,
  AccordionComponentFragment,
  ImageTextComponentFragment,
} from 'graphql/dynamic-components/GetDynamicComponents.generated';
import { NewsletterSignupComponentFragment } from 'graphql/newsletter/NewsletterSignupComponent.generated';
import type { TypedTextComponentFragment } from 'graphql/text/TextComponent.generated';

const NewsletterSignup = dynamic(
  () => import('components/domain/newsletter/NewsletterSignup')
);
const DynamicAccordion = dynamic(
  () => import('components/domain/dynamic-accordion/DynamicAccordion')
);
const OfferButtonBlock = dynamic(
  () => import('components/domain/geo/dynamic-components/OfferButtonBlock')
);
const GeoClimateInfo = dynamic(
  () => import('components/domain/geo/dynamic-components/GeoClimateInfo')
);
const DynamicGeoChildren = dynamic(
  () => import('components/domain/geo/dynamic-components/DynamicGeoChildren')
);
const GeoResortTopHotels = dynamic(
  () => import('components/domain/geo/dynamic-components/GeoResortTopHotels')
);
const DynamicGeoGallery = dynamic(
  () => import('components/domain/geo/dynamic-components/DynamicGeoGallery')
);
const DynamicGeoMap = dynamic(
  () => import('components/domain/geo/dynamic-components/map/DynamicGeoMap')
);
const MarketingTeaser = dynamic(
  () => import('components/domain/marketing/MarketingTeaser')
);
const ImageTextComponent = dynamic(
  () => import('components/domain/dynamic-components/ImageTextComponent')
);

const MediaGalleryLazyWrapper = styled.div(
  sx2CssThemeFn({
    mb: [4, '50px'],
  }),
  ({ theme }) => ({
    '.media-gallery': theme.mediaGallery.mediaGalleryStyles,
  })
);

type DynamicComponentUnion =
  | Bd4TravelRecommendationGroupFragment
  | BlogArticleRecommendationFragment
  | FlightWithPriceRecommendationFragment
  | FlightWithoutPriceRecommendationFragment
  | GeoRecommendationComponentFragment
  | MarketingRecommendationComponentFragment
  | MediaGalleryFragment
  | ProductRecommendationComponentFragment
  | TypedTextComponentFragment
  | ThemeRecommendationComponentFragment
  | NewsletterSignupComponentFragment
  | FaqFragment
  | AccordionComponentFragment
  | OfferButtonComponentFragment
  | SrlMapPinsComponentFragment
  | ClimateComponentFragment
  | GeoChildrenComponentFragment
  | ResortTopHotelsComponentFragment
  | GeoGalleryComponentFragment
  | MarketingTeaserComponentFragment
  | ImageTextComponentFragment
  | {
      __typename?:
        | 'BrandsComponent'
        | 'ContactModuleComponent'
        | 'EmployeesComponent'
        | 'HeroMediaGallery'
        | 'LinkListComponent'
        | 'NewsArticlesComponent'
        | 'ProductGalleryWithMapComponent'
        | 'StaticGeoRecommendation'
        | 'StaticProductRecommendation'
        | 'StaticThemeRecommendation'
        | 'AgencyContactComponent'
        | 'AgencyRecommendation'
        | 'RequestButtonComponent'
        | 'UspBoxesComponent'
        | 'OffersMapPlaceholderComponent'
        | 'StaticBlogRecommendation'
        | 'SrlButtonPlaceholderComponent'
        | 'BenefitsComponent';
    };

export default function DynamicComponent(props: {
  id: string;
  component: DynamicComponentUnion;
}): React.ReactElement {
  const { id, component } = props;

  switch (component.__typename) {
    case 'TextComponent':
      const { bottomPadding, topPadding } = component;

      const textBlockBottomPadding = getBottomPadding(bottomPadding);
      const textBlockTopPadding = getTopPadding(topPadding);

      return component.type === 'raw' ? (
        <RawTextBlock
          uid={id}
          title={component.title}
          text={component.text}
          className={`text-block raw-text ${textBlockTopPadding} ${textBlockBottomPadding}`}
        />
      ) : (
        <TextBlock
          id={id}
          title={component.title}
          text={component.text}
          className={`text-block markdown-text ${textBlockTopPadding} ${textBlockBottomPadding}`}
        />
      );
    case 'MediaGallery':
      return (
        <MediaGalleryLazyWrapper>
          <MediaGalleryLazy
            items={component.mediaItems}
            hasPreview={true}
            isShowIndex={false}
            className="media-gallery"
          />
        </MediaGalleryLazyWrapper>
      );
    case 'BlogArticleRecommendation':
    case 'GeoRecommendation':
    case 'ThemeRecommendation':
    case 'MarketingRecommendation':
    case 'ProductRecommendation':
    case 'FlightWithoutPriceRecommendation':
    case 'FlightWithPriceRecommendation':
    case 'BD4TravelRecommendationGroup':
      return (
        <RecommendationGroup
          fontColor={component.fontColor}
          name={component.name}
          mainText={component.mainText}
          items={component.items}
          tracking={'tracking' in component ? component.tracking : undefined}
        />
      );
    case 'FaqComponent':
      return (
        <AccordionWrapper>
          <Accordion mode={AccordionMode.MULTIPLE}>
            {component.items.map(item => {
              return (
                <AccordionItem key={item.title} title={item.title}>
                  <div
                    className="accordion-rich-text"
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </AccordionItem>
              );
            })}
          </Accordion>
        </AccordionWrapper>
      );
    case 'OffersMapPlaceholderComponent':
      return <ThemeMapAccessBlock />;
    case 'SrlButtonPlaceholderComponent':
      return <ThemeSRLButtonBlock />;
    case 'NewsletterSignupComponent':
      return <NewsletterSignup newsletterSignupInformation={component} />;
    case 'AccordionComponent':
      return <DynamicAccordion data={component} />;
    case 'OfferButtonComponent':
      const { caption, link } = component;
      return <OfferButtonBlock link={link}>{caption}</OfferButtonBlock>;
    case 'SrlMapPinsComponent':
      return <DynamicGeoMap data={component} />;
    case 'ClimateComponent':
      return <GeoClimateInfo data={component} />;
    case 'GeoChildrenComponent':
      return <DynamicGeoChildren data={component} />;
    case 'ResortTopHotelsComponent':
      return <GeoResortTopHotels data={component} />;
    case 'GeoGalleryComponent':
      return <DynamicGeoGallery data={component} />;
    case 'MarketingTeaserComponent':
      return <MarketingTeaser data={component} />;
    case 'ImageTextComponent':
      return <ImageTextComponent data={component} />;
  }

  return null;
}
