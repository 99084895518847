import React, { useRef } from 'react';
import styled from 'styled-components';
import { MapProvider } from '@hotelplan/components.common.map';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useScrollElement } from '@hotelplan/libs.context.ios-scroll-block';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  mapFormWrapperStyles,
  mapContainerStyles,
  mapCardsContainerStyles,
} from 'components/domain/map/Map.styles';
import useMapForm from 'components/domain/map/useMapForm';
import SRLFiltersForm from 'components/domain/srl/filters/SRLFiltersForm';
import SRLSearchFilterField from 'components/domain/srl/SRLSearchFilterField';
import SRLMap from './SRLMap';
import SRLMapDestinationStack from './SRLMapDestinationStack';
import SRLMapResults from './SRLMapResults';

const MapFormWrapper = styled.div.attrs({
  'data-id': `map-form-wrapper`,
})(
  {
    display: 'flex',
  },
  mapFormWrapperStyles
);

const MapFiltersWrapper = styled.div.attrs({
  'data-id': `map-filters-wrapper`,
})(
  sx2CssThemeFn({
    maxHeight: '100%',
    width: `${(4 / 12) * 100}%`,
    px: '15px',
    '.search-filters-wrap': {
      overflowY: [null, 'auto'],
      flex: '1 auto',
    },
  })
);

const MapFiltersForm = styled(SRLFiltersForm)(({ theme: { radii } }) => ({
  height: '100%',
  overflow: 'hidden',
  borderRadius: radii.roundedTop,
}));

const MapContainerWrapper = styled.div.attrs({
  'data-id': `mapContainerWrapper`,
})(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    width: ['100%', `${(8 / 12) * 100}%`],
    px: [0, '15px'],
  })
);

const MapContainer = styled.div.attrs({
  'data-id': 'mapContainer',
})(mapContainerStyles);

const MapCards = styled.div.attrs({
  'data-id': `map-cards`,
})(mapCardsContainerStyles);

const SRLMapForm: React.FC = () => {
  const { mobile } = useDeviceType();

  const {
    mapContainerRef,
    mapProviderProps,
    selectedObjects,
    onSelectObjects,
    deselect,
  } = useMapForm();

  const srlMapCardsContainerEl = useRef<HTMLDivElement>(null);

  useScrollElement(srlMapCardsContainerEl, true);

  return (
    <SRLMapDestinationStack>
      <MapFormWrapper>
        {!mobile && (
          <MapFiltersWrapper>
            <MapFiltersForm>
              <SRLSearchFilterField />
            </MapFiltersForm>
          </MapFiltersWrapper>
        )}
        <MapContainerWrapper>
          <MapContainer ref={mapContainerRef}>
            <MapProvider {...mapProviderProps}>
              <SRLMap
                onSelect={onSelectObjects}
                onClickOnSelectedPin={deselect}
              />
            </MapProvider>
          </MapContainer>
          <MapCards ref={srlMapCardsContainerEl}>
            <SRLMapResults items={selectedObjects} onClose={deselect} />
          </MapCards>
        </MapContainerWrapper>
      </MapFormWrapper>
    </SRLMapDestinationStack>
  );
};

export default SRLMapForm;
