import noop from 'lodash/noop';
import { useFormContext } from '@hotelplan/components.common.forms';
import {
  SrlSearchControlCriteriaInput,
  SrlFilterCriteriaInput,
} from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import { useDestinationStack } from 'components/domain/destinationStack/DestinationStackContext';
import { FilterOptions } from 'components/domain/filters/Filters.constants';
import { mapFilterToCountsMapper } from 'components/domain/filters/Filters.mappers';
import {
  IFiltersFormState,
  TUseCounts,
  TFilterCountsLoader,
  TUseMaxPrice,
  IFilterCount,
} from 'components/domain/filters/Filters.types';
import {
  countsHooksFactory,
  TUsePrepareValuesForCountsRequest,
} from 'components/domain/filters/search-filter-input/blocks/FilterCounts';
import { mapSearchControlToCriteria } from 'components/domain/searchControl/SearchControl.mappers';
import { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/srl/SRL.mappers';
import { useGetSrlAccommodationSizeFilterCountsQuery } from 'graphql/srl/GetSRLAccommodationSizeFilterCounts.generated';
import { useGetSrlBeachFeaturesFilterCountsQuery } from 'graphql/srl/GetSRLBeachFeaturesFilterCounts.generated';
import { useGetSrlBoardTypesFilterCountsQuery } from 'graphql/srl/GetSRLBoardTypesFilterCounts.generated';
import { useGetSrlChildrenFeaturesFilterCountsQuery } from 'graphql/srl/GetSRLChildrenFeaturesFilterCounts.generated';
import { useGetSrlCultureFeaturesFilterCountsQuery } from 'graphql/srl/GetSRLCultureFeaturesFilterCounts.generated';
import { useGetSrlDepartureAirportsFilterCountsQuery } from 'graphql/srl/GetSRLDepartureAirportsFilterCounts.generated';
import { useGetSrlDirectFlightDepartureTimeFilterCountsQuery } from 'graphql/srl/GetSRLDirectFlightDepartureTimeFilterCounts.generated';
import { useGetSrlDistanceFeaturesFilterCountsQuery } from 'graphql/srl/GetSRLDistanceFeaturesFilterCounts.generated';
import { useGetSrlEnvironmentFeaturesFilterCountsQuery } from 'graphql/srl/GetSRLEnvironmentFeaturesFilterCounts.generated';
import { useGetSrlHotelFeaturesFilterCountsQuery } from 'graphql/srl/GetSRLHotelFeaturesFilterCounts.generated';
import { useGetSrlHotelplanRatingsFilterCountsQuery } from 'graphql/srl/GetSRLHotelplanRatingsFilterCounts.generated';
import { useGetSrlMainFeaturesFilterCountsQuery } from 'graphql/srl/GetSRLMainFeaturesFilterCounts.generated';
import { useGetSrlMaxPricePerPersonFilterCountsQuery } from 'graphql/srl/GetSRLMaxPricePerPersonFilterCounts.generated';
import { useGetSrlPopularFiltersFilterCountsQuery } from 'graphql/srl/GetSRLPopularFiltersFilterCounts.generated';
import { useGetSrlReturnFlightDepartureTimeFilterCountsQuery } from 'graphql/srl/GetSRLReturnFlightDepartureTimeFilterCounts.generated';
import { useGetSrlRoomTypesFilterCountsQuery } from 'graphql/srl/GetSRLRoomTypesFilterCounts.generated';
import { useGetSrlSportFeaturesFilterCountsQuery } from 'graphql/srl/GetSRLSportFeaturesFilterCounts.generated';
import { useGetSrlStopOversFilterCountsQuery } from 'graphql/srl/GetSRLStopOversFilterCounts.generated';
import { useGetSrlTotalFilterCountsQuery } from 'graphql/srl/GetSRLTotalFilterCounts.generated';
import { useGetSrlTripadvisorRatingsFilterCountsQuery } from 'graphql/srl/GetSRLTripadvisorRatingsFilterCounts.generated';
import { useGetSrlWellnessFeaturesFilterCountsQuery } from 'graphql/srl/GetSRLWellnessFeaturesFilterCounts.generated';
import type { ISRLState } from './SRL.types';

const usePrepareValuesForSRLCountsRequest: TUsePrepareValuesForCountsRequest<{
  searchControl: SrlSearchControlCriteriaInput;
  filters: SrlFilterCriteriaInput;
  subGeoFilters: string[] | null;
}> = () => {
  const { values: filters } = useFormContext<IFiltersFormState>();

  const {
    state: { searchControl, subGeoFilters },
  } = useSearchState<ISRLState>();
  const { current } = useDestinationStack();

  const searchControlFormState = {
    ...searchControl,
  } as ISearchControlFormState;

  // Patch search control travel destination
  // If destination stack context is presented
  if (current) {
    searchControlFormState.travelDestination = {
      destinations: current,
    };
  }

  return {
    prevChangedFilter: filters.prevChangedFilter,
    countsQueryVariables: {
      searchControl: mapSearchControlToCriteria(searchControlFormState),
      filters: mapSrlFilterValuesToSrlFilterCriteriaInput(filters),
      subGeoFilters: subGeoFilters,
    },
  };
};

const countsHooksFactorySRL = countsHooksFactory(
  usePrepareValuesForSRLCountsRequest
);

// NOTE: useGetSrlTotalFilterCountsQuery does not use urlParameter
// I hope it is okay and it will be skipped during request
export const useTotalResultsCounts = countsHooksFactorySRL(
  useGetSrlTotalFilterCountsQuery,
  data => {
    return data?.srl.search.single.__typename === 'SrlSingleResult'
      ? { count: data.srl.search.single.productsTotal, id: '' }
      : null;
  },
  'total' as const
);

export const useTripAdvisorRatingCounts = countsHooksFactorySRL(
  useGetSrlTripadvisorRatingsFilterCountsQuery,
  data => mapFilterToCountsMapper(data?.srl.searchControls.mainFilter.taRating),
  FilterOptions.tripAdvisorRating
);

export const useHotelplanRatingCounts = countsHooksFactorySRL(
  useGetSrlHotelplanRatingsFilterCountsQuery,
  data => mapFilterToCountsMapper(data?.srl.searchControls.mainFilter.hpRating),
  FilterOptions.hotelplanRating
);

export const useFlightStopOverCounts = countsHooksFactorySRL(
  useGetSrlStopOversFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(data?.srl.searchControls.mainFilter.stopOvers),
  FilterOptions.flightStopOver
);

export const useReturnFlightDepartureTimeCounts = countsHooksFactorySRL(
  useGetSrlReturnFlightDepartureTimeFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(
      data?.srl.searchControls.mainFilter.returnFlightDepartureTime
    ),
  FilterOptions.returnFlightDepartureTime
);

export const useRoomTypesCounts = countsHooksFactorySRL(
  useGetSrlRoomTypesFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(data?.srl.searchControls.mainFilter.roomTypes),
  FilterOptions.roomTypes
);

export const useAccommodationSizeCounts = countsHooksFactorySRL(
  useGetSrlAccommodationSizeFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(
      data?.srl.searchControls.mainFilter.accommodationSize
    ),
  FilterOptions.accommodationSize
);

export const useDirectFlightDepartureTimeCounts = countsHooksFactorySRL(
  useGetSrlDirectFlightDepartureTimeFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(
      data?.srl.searchControls.mainFilter.directFlightDepartureTime
    ),
  FilterOptions.directFlightDepartureTime
);

export const useBoardTypesCounts = countsHooksFactorySRL(
  useGetSrlBoardTypesFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(data?.srl.searchControls.mainFilter.boardTypes),
  FilterOptions.boardTypes
);

export const useDepartureAirportsCounts = countsHooksFactorySRL(
  useGetSrlDepartureAirportsFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(
      data?.srl.searchControls.mainFilter.departureAirports
    ),
  FilterOptions.departureAirports
);

export const useMainFeaturesCounts = countsHooksFactorySRL(
  useGetSrlMainFeaturesFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(data?.srl.searchControls.mainFilter.mainFeatures),
  FilterOptions.mainFeatures
);

export const usePopularFilterCounts = countsHooksFactorySRL(
  useGetSrlPopularFiltersFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(data?.srl.searchControls.mainFilter.popularFilters),
  FilterOptions.popularFilters
);

export const useChildrenFeaturesCounts = countsHooksFactorySRL(
  useGetSrlChildrenFeaturesFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(
      data?.srl.searchControls.mainFilter.childrenFeatures
    ),
  FilterOptions.childrenFeatures
);

export const useBeachFeaturesCounts = countsHooksFactorySRL(
  useGetSrlBeachFeaturesFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(data?.srl.searchControls.mainFilter.beachFeatures),
  FilterOptions.beachFeatures
);

export const useDistanceFeaturesCounts = countsHooksFactorySRL(
  useGetSrlDistanceFeaturesFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(
      data?.srl.searchControls.mainFilter.distanceFeatures
    ),
  FilterOptions.distanceFeatures
);

export const useSportFeaturesCounts = countsHooksFactorySRL(
  useGetSrlSportFeaturesFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(data?.srl.searchControls.mainFilter.sportFeatures),
  FilterOptions.sportFeatures
);

export const useWellnessFeaturesCounts = countsHooksFactorySRL(
  useGetSrlWellnessFeaturesFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(
      data?.srl.searchControls.mainFilter.wellnessFeatures
    ),
  FilterOptions.wellnessFeatures
);

export const useHotelFeaturesCounts = countsHooksFactorySRL(
  useGetSrlHotelFeaturesFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(data?.srl.searchControls.mainFilter.hotelFeatures),
  FilterOptions.hotelFeatures
);

export const useEnvironmentFeaturesCounts = countsHooksFactorySRL(
  useGetSrlEnvironmentFeaturesFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(
      data?.srl.searchControls.mainFilter.environmentFeatures
    ),
  FilterOptions.environmentFeatures
);

export const useCultureFeaturesCounts = countsHooksFactorySRL(
  useGetSrlCultureFeaturesFilterCountsQuery,
  data =>
    mapFilterToCountsMapper(
      data?.srl.searchControls.mainFilter.cultureFeatures
    ),
  FilterOptions.cultureFeatures
);

const useMaxPricePerPersonCountsInternal = countsHooksFactorySRL(
  useGetSrlMaxPricePerPersonFilterCountsQuery,
  data => {
    let minPrice;
    if (data?.srl.search.single.__typename === 'SrlSingleResult') {
      minPrice = data?.srl.search.single?.cheapestProduct?.price.amount;
    }
    return minPrice ? parseInt(minPrice) : undefined;
  },
  FilterOptions.maxPricePerPerson
);

export const useMaxPricePerPersonCounts: TUseMaxPrice = props => {
  const { loading, counts } = useMaxPricePerPersonCountsInternal(props);
  return {
    minPrice: counts,
    loading,
  };
};

export const useMaxFlightDurationCounts: TUseCounts<IFilterCount> = () => {
  return {
    counts: { count: 0, id: '' },
    loading: false,
    error: false,
  };
};

const SRLFilterCountsLoader: TFilterCountsLoader = {
  total: useTotalResultsCounts,
  beachFeatures: useBeachFeaturesCounts,
  wellnessFeatures: useWellnessFeaturesCounts,
  tripAdvisorRating: useTripAdvisorRatingCounts,
  sportFeatures: useSportFeaturesCounts,
  roomTypes: useRoomTypesCounts,
  accommodationSize: useAccommodationSizeCounts,
  popularFilters: usePopularFilterCounts,
  mainFeatures: useMainFeaturesCounts,
  hotelplanRating: useHotelplanRatingCounts,
  hotelFeatures: useHotelFeaturesCounts,
  boardTypes: useBoardTypesCounts,
  childrenFeatures: useChildrenFeaturesCounts,
  cultureFeatures: useCultureFeaturesCounts,
  departureAirports: useDepartureAirportsCounts,
  distanceFeatures: useDistanceFeaturesCounts,
  environmentFeatures: useEnvironmentFeaturesCounts,
  flightStopOver: useFlightStopOverCounts,
  maxPricePerPerson: useMaxPricePerPersonCounts,
  maxFlightDuration: useMaxFlightDurationCounts,
  directFlightDepartureTime: useDirectFlightDepartureTimeCounts,
  returnFlightDepartureTime: useReturnFlightDepartureTimeCounts,
  flightStopOverDuration: noop as TUseCounts<IFilterCount[]>,
  directFlightArrivalTime: noop as TUseCounts<IFilterCount[]>,
  returnFlightArrivalTime: noop as TUseCounts<IFilterCount[]>,
  flightAirlines: noop as TUseCounts<IFilterCount[]>,
};

export default SRLFilterCountsLoader;
