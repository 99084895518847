import noop from 'lodash/noop';
import React from 'react';
import { MapLoader } from '@hotelplan/components.common.map';
import { ICanonicalTravelDestination } from '@hotelplan/components.common.travel-destination';
import { IFiltersFormState } from 'components/domain/filters/Filters.types';
import MapAccessPreview from 'components/domain/mapAccess/MapAccessPreview';
import MapAccessPreviewItems from 'components/domain/mapAccess/MapAccessPreviewItems';
import SRLMapItems from 'components/domain/srlMap/SRLMapItems';
import useSRLMapItems from 'components/domain/srlMap/useSRLMapItems';

interface IGeoMapAccessPreviewProps {
  destinations?: ICanonicalTravelDestination[];
  filters?: IFiltersFormState;
  onOpen?(): void;
}

const SRLMapAccessPreview: React.FC<IGeoMapAccessPreviewProps> = props => {
  const { destinations, onOpen = noop, filters } = props;

  const { loading, mapItems } = useSRLMapItems({
    destinations,
    filters,
  });

  return (
    <MapAccessPreview onOpen={onOpen}>
      {loading ? (
        <MapLoader />
      ) : (
        <MapAccessPreviewItems items={mapItems}>
          <SRLMapItems mapItems={mapItems} />
        </MapAccessPreviewItems>
      )}
    </MapAccessPreview>
  );
};

export default SRLMapAccessPreview;
