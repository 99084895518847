import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { PriceFragmentFragmentDoc } from 'graphql/price/Price.generated';
import { FlightItemFragmentDoc } from 'graphql/flights/FlightItem.generated';
export type FlightSrlOfferItemFragment = {
  __typename?: 'FlightOffer';
  offerId: string;
  waitingScreenImage: string;
  pricePerPerson: { __typename?: 'Price'; amount: string; currency: string };
  totalPrice: { __typename?: 'Price'; amount: string; currency: string };
  forwardFlight: {
    __typename?: 'Flight';
    departureTime: any;
    arrivalTime: any;
    duration: string;
    arrivalDate: any;
    departureDate: any;
    overnight: boolean;
    departure: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    arrival: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    segments: Array<{
      __typename?: 'FlightSegment';
      airportWaiting?: string | null;
      arrivalDate: any;
      arrivalTime: any;
      departureDate: any;
      departureTime: any;
      flightClass: Types.FlightClass;
      flightNumber: string;
      segmentDuration: string;
      airline: {
        __typename?: 'Airline';
        airlineLogo: string;
        iata: string;
        name: string;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
    }>;
  };
  returnFlight?: {
    __typename?: 'Flight';
    departureTime: any;
    arrivalTime: any;
    duration: string;
    arrivalDate: any;
    departureDate: any;
    overnight: boolean;
    departure: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    arrival: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    segments: Array<{
      __typename?: 'FlightSegment';
      airportWaiting?: string | null;
      arrivalDate: any;
      arrivalTime: any;
      departureDate: any;
      departureTime: any;
      flightClass: Types.FlightClass;
      flightNumber: string;
      segmentDuration: string;
      airline: {
        __typename?: 'Airline';
        airlineLogo: string;
        iata: string;
        name: string;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
    }>;
  } | null;
};

export const FlightSrlOfferItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightSRLOfferItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlightOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PriceFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PriceFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forwardFlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FlightItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FlightItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'offerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'waitingScreenImage' },
          },
        ],
      },
    },
    ...PriceFragmentFragmentDoc.definitions,
    ...FlightItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
