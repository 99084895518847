import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FeatureList } from 'config/pageConfig.constants';

const usePopularFiltersFeatureToggle = () => {
  const { getVariant } = useFeatureToggle();

  const { name } = getVariant(FeatureList.POPULAR_FILTER_ON_TOP);

  return name === 'open-on-top';
};

export default usePopularFiltersFeatureToggle;
