import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetThemeToSrlUrlQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  themeSearchCriteriaInput: Types.ThemeSearchCriteriaInput;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetThemeToSrlUrlQuery = {
  __typename?: 'Query';
  theme: {
    __typename?: 'ThemeContext';
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
  };
};

export const GetThemeToSrlUrlDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetThemeToSrlUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'themeSearchCriteriaInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ThemeSearchCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'themeSearchCriteriaInput',
                        },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'targetPageType' },
                      value: { kind: 'EnumValue', value: 'SRL' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetPageType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useGetThemeToSrlUrlQuery__
 *
 * To run a query within a React component, call `useGetThemeToSrlUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeToSrlUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeToSrlUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *      themeSearchCriteriaInput: // value for 'themeSearchCriteriaInput'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetThemeToSrlUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetThemeToSrlUrlQuery,
    GetThemeToSrlUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetThemeToSrlUrlQuery,
    GetThemeToSrlUrlQueryVariables
  >(GetThemeToSrlUrlDocument, options);
}
export function useGetThemeToSrlUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetThemeToSrlUrlQuery,
    GetThemeToSrlUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetThemeToSrlUrlQuery,
    GetThemeToSrlUrlQueryVariables
  >(GetThemeToSrlUrlDocument, options);
}
export type GetThemeToSrlUrlQueryHookResult = ReturnType<
  typeof useGetThemeToSrlUrlQuery
>;
export type GetThemeToSrlUrlLazyQueryHookResult = ReturnType<
  typeof useGetThemeToSrlUrlLazyQuery
>;
export type GetThemeToSrlUrlQueryResult = Apollo.QueryResult<
  GetThemeToSrlUrlQuery,
  GetThemeToSrlUrlQueryVariables
>;
