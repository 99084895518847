import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import {
  SrlMapGeoPinFragmentDoc,
  SrlMapProductPinFragmentDoc,
} from './SRLMapFragments.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlMapPinsQueryVariables = Types.Exact<{
  searchControl: Types.SrlSearchControlCriteriaInput;
  subGeoFilters?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  filters?: Types.InputMaybe<Types.SrlFilterCriteriaInput>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetSrlMapPinsQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    search: {
      __typename?: 'SrlResultContext';
      mapPins: {
        __typename?: 'SrlMapPinsComponent';
        status: Types.Status;
        message?: string | null;
        cheapestGeo?: {
          __typename?: 'SrlMapGeoPin';
          zoom: number;
          geo: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          };
          price: { __typename?: 'Price'; amount: string; currency: string };
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        } | null;
        cheapestProduct?: {
          __typename?: 'SrlMapProductPin';
          id: string;
          name: string;
          top: boolean;
          zoom: number;
          price: { __typename?: 'Price'; amount: string; currency: string };
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        } | null;
        geoPins: Array<{
          __typename?: 'SrlMapGeoPin';
          zoom: number;
          geo: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          };
          price: { __typename?: 'Price'; amount: string; currency: string };
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        }>;
        productPins: Array<{
          __typename?: 'SrlMapProductPin';
          id: string;
          name: string;
          top: boolean;
          zoom: number;
          price: { __typename?: 'Price'; amount: string; currency: string };
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        }>;
      };
    };
  };
};

export const GetSrlMapPinsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSRLMapPins' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SrlSearchControlCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subGeoFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlFilterCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'search' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchControl' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchControl' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subGeoFilter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'subGeoFilters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mapPins' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cheapestGeo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'srlMapGeoPin',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cheapestProduct' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'srlMapProductPin',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'geoPins' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'srlMapGeoPin',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productPins' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'srlMapProductPin',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SrlMapGeoPinFragmentDoc.definitions,
    ...SrlMapProductPinFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetSrlMapPinsQuery__
 *
 * To run a query within a React component, call `useGetSrlMapPinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlMapPinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlMapPinsQuery({
 *   variables: {
 *      searchControl: // value for 'searchControl'
 *      subGeoFilters: // value for 'subGeoFilters'
 *      filters: // value for 'filters'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetSrlMapPinsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSrlMapPinsQuery,
    GetSrlMapPinsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlMapPinsQuery,
    GetSrlMapPinsQueryVariables
  >(GetSrlMapPinsDocument, options);
}
export function useGetSrlMapPinsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlMapPinsQuery,
    GetSrlMapPinsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlMapPinsQuery,
    GetSrlMapPinsQueryVariables
  >(GetSrlMapPinsDocument, options);
}
export type GetSrlMapPinsQueryHookResult = ReturnType<
  typeof useGetSrlMapPinsQuery
>;
export type GetSrlMapPinsLazyQueryHookResult = ReturnType<
  typeof useGetSrlMapPinsLazyQuery
>;
export type GetSrlMapPinsQueryResult = Apollo.QueryResult<
  GetSrlMapPinsQuery,
  GetSrlMapPinsQueryVariables
>;
