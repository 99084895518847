import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { PriceFragmentFragmentDoc } from 'graphql/price/Price.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlMaxPricePerPersonFilterCountsQueryVariables = Types.Exact<{
  searchControl: Types.SrlSearchControlCriteriaInput;
  subGeoFilters?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  filters?: Types.InputMaybe<Types.SrlFilterCriteriaInput>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetSrlMaxPricePerPersonFilterCountsQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    search: {
      __typename?: 'SrlResultContext';
      single:
        | { __typename?: 'SrlEmptyResult' }
        | {
            __typename?: 'SrlSingleResult';
            cheapestProduct: {
              __typename?: 'SrlProductItem';
              offerId: string;
              price: { __typename?: 'Price'; amount: string; currency: string };
            };
          };
    };
  };
};

export const GetSrlMaxPricePerPersonFilterCountsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSRLMaxPricePerPersonFilterCounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SrlSearchControlCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subGeoFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlFilterCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'search' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchControl' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchControl' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subGeoFilter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'subGeoFilters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'single' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'SrlSingleResult',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cheapestProduct',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'price',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'PriceFragment',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'offerId',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PriceFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetSrlMaxPricePerPersonFilterCountsQuery__
 *
 * To run a query within a React component, call `useGetSrlMaxPricePerPersonFilterCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlMaxPricePerPersonFilterCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlMaxPricePerPersonFilterCountsQuery({
 *   variables: {
 *      searchControl: // value for 'searchControl'
 *      subGeoFilters: // value for 'subGeoFilters'
 *      filters: // value for 'filters'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetSrlMaxPricePerPersonFilterCountsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSrlMaxPricePerPersonFilterCountsQuery,
    GetSrlMaxPricePerPersonFilterCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlMaxPricePerPersonFilterCountsQuery,
    GetSrlMaxPricePerPersonFilterCountsQueryVariables
  >(GetSrlMaxPricePerPersonFilterCountsDocument, options);
}
export function useGetSrlMaxPricePerPersonFilterCountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlMaxPricePerPersonFilterCountsQuery,
    GetSrlMaxPricePerPersonFilterCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlMaxPricePerPersonFilterCountsQuery,
    GetSrlMaxPricePerPersonFilterCountsQueryVariables
  >(GetSrlMaxPricePerPersonFilterCountsDocument, options);
}
export type GetSrlMaxPricePerPersonFilterCountsQueryHookResult = ReturnType<
  typeof useGetSrlMaxPricePerPersonFilterCountsQuery
>;
export type GetSrlMaxPricePerPersonFilterCountsLazyQueryHookResult = ReturnType<
  typeof useGetSrlMaxPricePerPersonFilterCountsLazyQuery
>;
export type GetSrlMaxPricePerPersonFilterCountsQueryResult = Apollo.QueryResult<
  GetSrlMaxPricePerPersonFilterCountsQuery,
  GetSrlMaxPricePerPersonFilterCountsQueryVariables
>;
