import React, { createContext, useContext } from 'react';

interface IDynamicComponentsContextValue {
  count: number | undefined;
}

interface IDynamicComponentsProviderProps extends React.PropsWithChildren<{}> {
  value: IDynamicComponentsContextValue;
}

const DynamicComponentsContext = createContext<IDynamicComponentsContextValue>({
  count: undefined,
});

export const DynamicComponentsProvider: React.FC<IDynamicComponentsProviderProps> = ({
  value,
  children,
}) => {
  return (
    <DynamicComponentsContext.Provider value={value}>
      {children}
    </DynamicComponentsContext.Provider>
  );
};

export function useDynamicComponentsContext() {
  return useContext(DynamicComponentsContext);
}
