import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { AirportFragmentFragmentDoc } from './Airport.generated';
export type FlightPartitionFragment = {
  __typename?: 'FlightPartition';
  flightClass: Types.FlightClass;
  departureAirport?: {
    __typename?: 'Airport';
    iata?: string | null;
    name?: string | null;
  } | null;
  arrivalAirport?: {
    __typename?: 'Airport';
    iata?: string | null;
    name?: string | null;
  } | null;
  travelPeriod: {
    __typename?: 'ExactTravelPeriod';
    returnDate: string;
    departureDate: string;
  };
};

export const FlightPartitionFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'flightPartition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlightPartition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'flightClass' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'departureAirport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'airportFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'arrivalAirport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'airportFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travelPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departureDate' },
                },
              ],
            },
          },
        ],
      },
    },
    ...AirportFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
