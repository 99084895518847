import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import React from 'react';
import { PageType } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import {
  useGSSContext,
  mapGlobalSearchStateToSearchControlCriteriaInput,
} from '@hotelplan/libs.gss';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import { useSearchState } from '@hotelplan/libs.search-state';
import RecommendationListSkeleton from 'components/domain/recommendation-list/RecommendationList.skeleton';
import type { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import {
  GetDynamicComponentsQueryVariables,
  useGetDynamicComponentsQuery,
} from 'graphql/dynamic-components/GetDynamicComponents.generated';
import DynamicComponent from './DynamicComponent';
import {
  DynamicComponentBackgroundImage,
  DynamicComponentStyles,
  DynamicComponentWrapper,
} from './DynamicComponents.styles';
import {
  getBackgroundImage,
  getStyle,
  StyleSettingsDynamicComponents,
} from './DynamicComponents.utils';
import { DynamicComponentsProvider } from './DynamicComponentsContext';

export interface IDynamicComponentsProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'placeholder' | 'id'> {
  placeholder?: React.ReactNode;
  ssr?: boolean;
}

export const defaultPlaceholder = Array.from({ length: 3 }, (__, i) => (
  <RecommendationListSkeleton hasTitle={true} key={i} />
));

export default function DynamicComponents(props: IDynamicComponentsProps) {
  const { asPath: uri } = useRouter();
  const { searchQuery } = useSearchState();
  const { id } = useObjectId();
  const {
    placeholder = defaultPlaceholder,
    ssr = false,
    ...sectionProps
  } = props;
  const { gss } = useGSSContext<ISearchControlFormState>();

  const pageType = usePageType<PageType>();

  const variables: Omit<GetDynamicComponentsQueryVariables, 'context'> = {
    id: id || undefined,
    pageType,
    uri,
    searchQuery: searchQuery || undefined,
    gss: isEmpty(gss)
      ? undefined
      : mapGlobalSearchStateToSearchControlCriteriaInput(gss),
  };

  const { data, loading } = useGetDynamicComponentsQuery({
    ssr,
    variables,
  });

  useIsomorphicLayoutEffect(() => {
    window.history.scrollRestoration = 'manual';
    const elemId = uri.replace(/.*\#/, '');

    const rId = requestAnimationFrame(() => {
      document.getElementById(elemId)?.scrollIntoView();
    });
    return () => cancelAnimationFrame(rId);
  }, [uri, data]);

  if (!data?.components.length && !loading) return null;

  return (
    <section data-id="dynamic-components" {...sectionProps}>
      <DynamicComponentsProvider value={{ count: data?.components.length }}>
        {data?.components.map((component, i) => {
          if (
            ('items' in component && !component.items.length) ||
            (component.__typename === 'AccordionComponent' &&
              !component.components.length)
          ) {
            return null;
          }
          const image = getBackgroundImage(component);
          return (
            <DynamicComponentStyles
              key={i}
              backgroundColor={
                component.__typename !== 'MarketingTeaserComponent'
                  ? getStyle(
                      component,
                      StyleSettingsDynamicComponents.BACKGROUND_COLOR
                    )
                  : null
              }
            >
              {image ? <DynamicComponentBackgroundImage {...image} /> : null}

              <DynamicComponentWrapper
                withoutContainer={component.__typename === 'AccordionComponent'}
              >
                <DynamicComponent
                  id={`dc-${pageType.toLowerCase()}-${
                    component.__typename
                  }-${i}`}
                  component={component}
                />
              </DynamicComponentWrapper>
            </DynamicComponentStyles>
          );
        })}
        {!data && loading ? placeholder : null}
      </DynamicComponentsProvider>
    </section>
  );
}
