import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { useTransfer } from '@hotelplan/libs.transfer';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useDynamicComponentsContext } from 'components/domain/dynamic-components/DynamicComponentsContext';
import useGetThemeShowMoreOffersLink from 'components/domain/theme/useGetThemeShowMoreOffersLink';

const TextBlockButtonWrapper = styled.div(
  sx2CssThemeFn({
    px: [4, 0],
    mb: [5, '55px'],
    '.text-block-button': {
      display: 'block',
      width: ['100%', 'auto'],
      mx: [null, 'auto'],
    },
  })
);

export default function ThemeSRLButtonBlock(): React.ReactElement {
  const [t] = useTranslation(['theme', 'common']);
  const { transfer } = useTransfer();
  const { id } = useObjectId();
  const { count: dynamicComponentsCount } = useDynamicComponentsContext();

  const { url, loading: linkLoading } = useGetThemeShowMoreOffersLink(id);

  if (!dynamicComponentsCount) return null;

  return (
    <TextBlockButtonWrapper>
      <BsButton
        type="button"
        disabled={linkLoading || !url}
        className="text-block-button"
        onClick={(): void => url && transfer(url)}
      >
        {t('common:show.all.offers')}
      </BsButton>
    </TextBlockButtonWrapper>
  );
}
