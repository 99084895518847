import noop from 'lodash/noop';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { MapPin, MapPinView } from '@hotelplan/components.common.map-pin';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { SrlMapGeoPinFragment } from 'graphql/srlMap/SRLMapFragments.generated';

type Geo = SrlMapGeoPinFragment;

interface ISRLMapGeoPinProps {
  geo: Geo;
  onClick?(geo: Geo, pin: MapPinView<Geo>): void;
}

const PinPrice = styled.div(
  sx2CssThemeFn({
    whiteSpace: 'nowrap',
    fontWeight: ['bold', 'normal'],
  })
);

const SRLMapGeoPin: React.FC<ISRLMapGeoPinProps> = props => {
  const { geo, onClick = noop } = props;
  const [t] = useTranslation('results');

  return (
    <MapPin coordinates={geo.coordinates} object={geo} onClick={onClick}>
      <div>
        <div>{geo.geo.name}</div>
        <PinPrice>
          {t('results:price.from', {
            price: `${geo.price.currency} ${geo.price.amount}`,
          })}
        </PinPrice>
      </div>
    </MapPin>
  );
};

export default SRLMapGeoPin;
