import React, { useMemo } from 'react';
import { trackRecommendationsLoaded } from '@hotelplan/libs.tracking';
import RecommendationList from 'components/domain/recommendation-list/RecommendationList';
import { mapRecommendationsToTrackableData } from 'components/domain/recommendationGroup/Recommendations.mappers';
import { Bd4TravelRecommendationTrackingFragment } from 'graphql/bd4Travel/BD4TravelRecommendationTracking.generated';
import {
  RecommendationGroupHeading,
  GroupWrapper,
  MainText,
} from './RecommendationGroup.styles';
import {
  getRecommendationComponent,
  TRecommendation,
} from './RecommendationGroup.utils';

interface IRecommendationGroupProps {
  name: string;
  mainText?: string;
  items: TRecommendation[];
  tracking?: Bd4TravelRecommendationTrackingFragment | null;
  fontColor?: string;
  withoutTitle?: boolean;
  className?: string;
}

const RecommendationGroup: React.FC<IRecommendationGroupProps> = props => {
  const {
    name,
    items,
    tracking,
    mainText,
    fontColor,
    withoutTitle,
    className,
  } = props;

  const itemsLength = useMemo(() => items.length, [items]);

  const render = useMemo(() => getRecommendationComponent(name, itemsLength), [
    name,
    itemsLength,
  ]);

  return (
    <GroupWrapper className="group-wrapper">
      {withoutTitle ? null : (
        <RecommendationGroupHeading
          className={'group-heading'}
          fontColor={fontColor}
        >
          {name}
        </RecommendationGroupHeading>
      )}

      {mainText ? (
        <MainText
          className="recommendation-group-main-text"
          dangerouslySetInnerHTML={{ __html: mainText }}
          fontColor={fontColor}
        />
      ) : null}
      <RecommendationList
        className={className}
        onInit={showedRecommendations => {
          trackRecommendationsLoaded(
            mapRecommendationsToTrackableData(
              name,
              showedRecommendations,
              tracking
            )
          );
        }}
        recommendations={items}
        render={render}
      />
    </GroupWrapper>
  );
};

export default RecommendationGroup;
