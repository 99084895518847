import styled from 'styled-components';
import { MediaItemCard } from '@hotelplan/components.common.media-item';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import GroupHeading from 'components/domain/heading/GroupHeading';

export const GroupWrapper = styled.div.attrs({
  'data-id': `recommendation-group`,
})(
  sx2CssThemeFn({
    px: [4, 0],
    pb: '50px',
  })
);

export const MainText = styled.div.attrs({
  'data-id': `recommendation-group-main-text`,
})<{ fontColor?: string }>(({ fontColor, theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.MEDIUM,
    pt: ['10px', 3],
    color: fontColor,
  })
);

export const RecommendationGroupHeading = styled(GroupHeading).attrs({
  'data-id': `recommendation-group-heading`,
})<{ fontColor?: string }>(({ fontColor }) => ({ color: fontColor }));

export const FlightRecommendationMediaCard = styled(MediaItemCard)<{
  allCount: number;
}>(({ allCount }) =>
  sx2CssThemeFn({
    height: [allCount !== 1 ? '235px' : '325px', '370px'],
  })
);
