import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { ProductItemFragmentDoc } from 'graphql/srl/SRLFragments.generated';
import { CoordinatesFragmentDoc } from './Coordinates.generated';
export type ProductMapItemFragment = {
  __typename?: 'SrlProductItem';
  mapZoom: number;
  giata: string;
  adults: number;
  children: number;
  hpRating?: number | null;
  taRating?: number | null;
  provider?: string | null;
  productCode?: string | null;
  type: Types.ProductType;
  distanceInfo?: string | null;
  duration: number;
  disrupter?: string | null;
  mealType: string;
  name: string;
  departureDate: string;
  returnDate: string;
  productClass: Types.ProductClass;
  fromTo?: string | null;
  offerId: string;
  inWishlist: boolean;
  transferIncluded?: boolean | null;
  taReviews?: number | null;
  coordinates: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  };
  image: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  geoBreadcrumbs: Array<{
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  }>;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  rooms?: Array<{
    __typename?: 'Room';
    boardType?: string | null;
    bookingCode?: string | null;
    roomType?: string | null;
  } | null> | null;
  featureSummary: Array<{
    __typename?: 'ProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'ProductFeature';
      id: string;
      name: string;
      enabled?: boolean | null;
    }>;
  }>;
  price: { __typename?: 'Price'; amount: string; currency: string };
  totalPrice: { __typename?: 'Price'; amount: string; currency: string };
  wishlistItem?: {
    __typename?: 'WishlistItem';
    id: string;
    inWishlist: boolean;
  } | null;
  transferPrice?: {
    __typename?: 'Price';
    amount: string;
    currency: string;
  } | null;
};

export const ProductMapItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productMapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlProductItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'productItem' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'coordinates' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mapZoom' } },
        ],
      },
    },
    ...ProductItemFragmentDoc.definitions,
    ...CoordinatesFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
