import { mapSearchControlToCriteria } from 'components/domain/searchControl/SearchControl.mappers';
import { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { useGetThemeToSrlUrlQuery } from 'graphql/theme/GetThemeToSrlUrl.generated';
import useThemeSearchControlState from './useThemeSearchControlState';

const useGetThemeShowMoreOffersLink = (id: string) => {
  const {
    searchControlState,
    loading: controlValuesLoading,
  } = useThemeSearchControlState(id);

  const { data, loading } = useGetThemeToSrlUrlQuery({
    ssr: false,
    skip: searchControlState?.type !== 'HOTEL',
    variables: {
      id,
      themeSearchCriteriaInput: mapSearchControlToCriteria(
        searchControlState as ISearchControlFormState
      ),
    },
  });

  return {
    loading: loading || controlValuesLoading,
    url: data?.theme.link,
  };
};

export default useGetThemeShowMoreOffersLink;
