import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { CheckboxGroupFilterOptionFragmentDoc } from './CheckboxGroupFilterOption.generated';
import { SliderFilterFragmentDoc } from './SliderFilter.generated';
import { SingleValueFilterFragmentDoc } from './SingleValueFilterFragment.generated';
import { RadiobuttonGroupFilterOptionFragmentDoc } from './RadiobuttonGroupFilterOption.generated';
export type UrlFilterOptionsFragment = {
  __typename?: 'FilterComponent';
  departureWeekdays: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  arrivalWeekdays: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  arrivalAirports: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  minPrice: {
    __typename?: 'SliderFilterComponent';
    caption: string;
    selected: string;
  };
  productCode?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  provider?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  flightProvider?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  refundableFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  directFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  returnFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  flightAirlines: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
};

export const UrlFilterOptionsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'URLFilterOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'departureWeekdays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'arrivalWeekdays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'arrivalAirports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SliderFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'singleValueFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'singleValueFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightProvider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'singleValueFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundableFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directFlightArrivalTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlightArrivalTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightAirlines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CheckboxGroupFilterOptionFragmentDoc.definitions,
    ...SliderFilterFragmentDoc.definitions,
    ...SingleValueFilterFragmentDoc.definitions,
    ...RadiobuttonGroupFilterOptionFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
