import React, { useEffect, useRef } from 'react';
import { mapTravelDestinationObjectToCanonical } from '@hotelplan/components.common.travel-destination';
import {
  useSearchState,
  useOnSearchStateChanges,
} from '@hotelplan/libs.search-state';
import {
  DestinationStackProvider,
  IDestinationStackContext,
} from 'components/domain/destinationStack/DestinationStackContext';
import type { ISRLState } from 'components/domain/srl/SRL.types';

const SRLMapDestinationStack: React.FC<{
  children?: React.ReactNode;
}> = props => {
  const { children } = props;
  const { state } = useSearchState<ISRLState>();
  const contextRef = useRef<IDestinationStackContext>(null);

  const destinations = (
    state.searchControl?.travelDestination?.destinations || []
  ).map(mapTravelDestinationObjectToCanonical);

  useEffect(
    function updateDestinationsOnStateLoadEffect() {
      if (!contextRef.current?.current?.length && destinations) {
        contextRef.current?.reset();
      }
    },
    [destinations]
  );

  useOnSearchStateChanges(() => {
    contextRef.current?.reset();
  });

  return (
    <DestinationStackProvider
      contextRef={contextRef}
      destinations={destinations}
    >
      {children}
    </DestinationStackProvider>
  );
};

export default SRLMapDestinationStack;
