import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { LinkFragmentFragmentDoc } from 'graphql/link/Link.generated';
import { MediaComponentFragmentDoc } from 'graphql/media/MediaComponent.generated';
import { FlightSrlOfferItemFragmentDoc } from 'graphql/flightSRL/FlightSRLOfferItem.generated';
export type FlightRecommendationWithPriceFragment = {
  __typename?: 'FlightRecommendationWithPriceItem';
  title: string;
  amount: string;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  media:
    | {
        __typename?: 'ExternalMediaItem';
        resource: string;
        title: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'ImageMediaItem';
        title: string;
        image: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'MusicMediaItem';
        resource: string;
        title: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'PdfMediaItem';
        resource: string;
        title: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'VideoMediaItem';
        resource: string;
        title: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      };
  offer: {
    __typename?: 'FlightOffer';
    offerId: string;
    waitingScreenImage: string;
    pricePerPerson: { __typename?: 'Price'; amount: string; currency: string };
    totalPrice: { __typename?: 'Price'; amount: string; currency: string };
    forwardFlight: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    };
    returnFlight?: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    } | null;
  };
};

export const FlightRecommendationWithPriceFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightRecommendationWithPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlightRecommendationWithPriceItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MediaComponent' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FlightSRLOfferItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LinkFragmentFragmentDoc.definitions,
    ...MediaComponentFragmentDoc.definitions,
    ...FlightSrlOfferItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
