import { useApolloClient } from '@apollo/client';
import type { DeviceType, PageLanguage } from '@hotelplan/graphql.types';
import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
import { useGSSContext } from '@hotelplan/libs.gss';
import {
  mapSearchControlValuesToFormState,
  mergeSearchControlState,
} from 'components/domain/searchControl/SearchControl.mappers';
import type { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import {
  GetThemeSearchControlValuesDocument,
  GetThemeSearchControlValuesQuery,
  GetThemeSearchControlValuesQueryVariables,
  useGetThemeSearchControlValuesQuery,
} from 'graphql/theme/GetThemeSearchControlValues.generated';

interface IUseThemeSearchControlStateOptions {
  skip?: boolean;
}

export default function useThemeSearchControlState(
  id: string,
  options: IUseThemeSearchControlStateOptions = {}
) {
  const { skip } = options;
  const client = useApolloClient();
  const requestContext = useRequestContext<DeviceType, PageLanguage>();

  const { data, loading } = useGetThemeSearchControlValuesQuery({
    variables: {
      id,
    },
    ssr: false,
    skip,
    onCompleted(dataValues) {
      try {
        client.writeQuery<
          GetThemeSearchControlValuesQuery,
          GetThemeSearchControlValuesQueryVariables
        >({
          query: GetThemeSearchControlValuesDocument,
          data: dataValues,
          variables: {
            id,
            travelType: dataValues.theme.searchControl.travelType,
            context: requestContext,
          },
        });
      } catch (e) {
        // ignore
      }
    },
  });

  const { gss } = useGSSContext<ISearchControlFormState>();

  const searchControl = mapSearchControlValuesToFormState(
    data?.theme.searchControl
  );

  return {
    searchControlState: mergeSearchControlState(searchControl, gss),
    loading,
  };
}
