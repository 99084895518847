import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { SrlFilterValuesFragmentDoc } from './SRLFilterValues.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlDefaultFilterValuesQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetSrlDefaultFilterValuesQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    packageControls: {
      __typename?: 'SrlSearchControlsContext';
      mainFilter: {
        __typename?: 'FilterComponent';
        roomTypes: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        accommodationSize?: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        } | null;
        boardTypes: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        popularFilters?: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        } | null;
        mainFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        childrenFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        beachFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        distanceFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        sportFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        wellnessFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        hotelFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        environmentFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        cultureFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        hpRating: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        taRating: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        maxPrice: {
          __typename?: 'SliderFilterComponent';
          caption: string;
          selected: string;
        };
        departureAirports: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        stopOvers: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        directFlightDepartureTime: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        returnFlightDepartureTime: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        maxFlightDuration: {
          __typename?: 'SliderFilterComponent';
          caption: string;
          selected: string;
        };
        departureWeekdays: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        arrivalWeekdays: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        arrivalAirports: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        minPrice: {
          __typename?: 'SliderFilterComponent';
          caption: string;
          selected: string;
        };
        productCode?: {
          __typename?: 'SingleValueFilterComponent';
          id: string;
          caption: string;
        } | null;
        provider?: {
          __typename?: 'SingleValueFilterComponent';
          id: string;
          caption: string;
        } | null;
        flightProvider?: {
          __typename?: 'SingleValueFilterComponent';
          id: string;
          caption: string;
        } | null;
        refundableFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        directFlightArrivalTime: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        returnFlightArrivalTime: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        flightAirlines: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        radius?: {
          __typename?: 'RadiusFilterComponent';
          label?: string | null;
          radius: number;
          center: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        } | null;
      };
    };
    hotelControls: {
      __typename?: 'SrlSearchControlsContext';
      mainFilter: {
        __typename?: 'FilterComponent';
        roomTypes: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        accommodationSize?: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        } | null;
        boardTypes: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        popularFilters?: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        } | null;
        mainFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        childrenFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        beachFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        distanceFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        sportFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        wellnessFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        hotelFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        environmentFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        cultureFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        hpRating: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        taRating: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        maxPrice: {
          __typename?: 'SliderFilterComponent';
          caption: string;
          selected: string;
        };
        departureAirports: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        stopOvers: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        directFlightDepartureTime: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        returnFlightDepartureTime: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        maxFlightDuration: {
          __typename?: 'SliderFilterComponent';
          caption: string;
          selected: string;
        };
        departureWeekdays: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        arrivalWeekdays: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        arrivalAirports: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        minPrice: {
          __typename?: 'SliderFilterComponent';
          caption: string;
          selected: string;
        };
        productCode?: {
          __typename?: 'SingleValueFilterComponent';
          id: string;
          caption: string;
        } | null;
        provider?: {
          __typename?: 'SingleValueFilterComponent';
          id: string;
          caption: string;
        } | null;
        flightProvider?: {
          __typename?: 'SingleValueFilterComponent';
          id: string;
          caption: string;
        } | null;
        refundableFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        directFlightArrivalTime: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        returnFlightArrivalTime: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        flightAirlines: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        radius?: {
          __typename?: 'RadiusFilterComponent';
          label?: string | null;
          radius: number;
          center: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        } | null;
      };
    };
  };
};

export const GetSrlDefaultFilterValuesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSRLDefaultFilterValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'packageControls' },
                  name: { kind: 'Name', value: 'searchControls' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'travelType' },
                      value: { kind: 'EnumValue', value: 'PACKAGE' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainFilter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SRLFilterValues' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'hotelControls' },
                  name: { kind: 'Name', value: 'searchControls' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'travelType' },
                      value: { kind: 'EnumValue', value: 'HOTEL' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainFilter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SRLFilterValues' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SrlFilterValuesFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetSrlDefaultFilterValuesQuery__
 *
 * To run a query within a React component, call `useGetSrlDefaultFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlDefaultFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlDefaultFilterValuesQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetSrlDefaultFilterValuesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSrlDefaultFilterValuesQuery,
    GetSrlDefaultFilterValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlDefaultFilterValuesQuery,
    GetSrlDefaultFilterValuesQueryVariables
  >(GetSrlDefaultFilterValuesDocument, options);
}
export function useGetSrlDefaultFilterValuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlDefaultFilterValuesQuery,
    GetSrlDefaultFilterValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlDefaultFilterValuesQuery,
    GetSrlDefaultFilterValuesQueryVariables
  >(GetSrlDefaultFilterValuesDocument, options);
}
export type GetSrlDefaultFilterValuesQueryHookResult = ReturnType<
  typeof useGetSrlDefaultFilterValuesQuery
>;
export type GetSrlDefaultFilterValuesLazyQueryHookResult = ReturnType<
  typeof useGetSrlDefaultFilterValuesLazyQuery
>;
export type GetSrlDefaultFilterValuesQueryResult = Apollo.QueryResult<
  GetSrlDefaultFilterValuesQuery,
  GetSrlDefaultFilterValuesQueryVariables
>;
