import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { UnionSearchControlFragmentDoc } from 'graphql/searchControl/UnionSearchControl.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetThemeSearchControlValuesQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  travelType?: Types.InputMaybe<Types.TravelType>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetThemeSearchControlValuesQuery = {
  __typename?: 'Query';
  theme: {
    __typename?: 'ThemeContext';
    searchControl:
      | {
          __typename: 'FlightSearchControlComponent';
          travelType: Types.TravelType;
          flightType: Types.FlightType;
          travellers: {
            __typename?: 'Travellers';
            adults: number;
            childrenDobs?: Array<string> | null;
          };
          flightPartitions?: Array<{
            __typename?: 'FlightPartition';
            flightClass: Types.FlightClass;
            departureAirport?: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            } | null;
            arrivalAirport?: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            } | null;
            travelPeriod: {
              __typename?: 'ExactTravelPeriod';
              returnDate: string;
              departureDate: string;
            };
          }> | null;
        }
      | {
          __typename: 'SearchControlComponent';
          travelType: Types.TravelType;
          rooms: number;
          period: {
            __typename?: 'TravelPeriodComponent';
            type: Types.SearchPeriodType;
            exactPeriod: {
              __typename?: 'ExactTravelPeriod';
              departureDate: string;
              returnDate: string;
            };
            flexiblePeriod: {
              __typename?: 'FlexibleTravelPeriod';
              departureDate: string;
              maxDuration: number;
              minDuration: number;
              returnDate: string;
            };
          };
          destinations?: Array<{
            __typename?: 'TravelDestination';
            id: string;
            name: string;
            type: Types.TravelDestinationType;
            mythosCode?: string | null;
            location?: {
              __typename?: 'GeoLocation';
              airport?: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              } | null;
              continent?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              countryGroup?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              country?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              region?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              destination?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              resort?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
            } | null;
          }> | null;
          travellers: {
            __typename?: 'Travellers';
            adults: number;
            childrenDobs?: Array<string> | null;
          };
          travellersDistribution?: {
            __typename?: 'TravellersDistribution';
            rooms: Array<{
              __typename?: 'RoomDistribution';
              adultsAmount: number;
              childrenIdx?: Array<number> | null;
            }>;
          } | null;
        };
  };
};

export const GetThemeSearchControlValuesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetThemeSearchControlValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'travelType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TravelType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchControl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'travelType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'travelType' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UnionSearchControl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UnionSearchControlFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetThemeSearchControlValuesQuery__
 *
 * To run a query within a React component, call `useGetThemeSearchControlValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeSearchControlValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeSearchControlValuesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      travelType: // value for 'travelType'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetThemeSearchControlValuesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetThemeSearchControlValuesQuery,
    GetThemeSearchControlValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetThemeSearchControlValuesQuery,
    GetThemeSearchControlValuesQueryVariables
  >(GetThemeSearchControlValuesDocument, options);
}
export function useGetThemeSearchControlValuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetThemeSearchControlValuesQuery,
    GetThemeSearchControlValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetThemeSearchControlValuesQuery,
    GetThemeSearchControlValuesQueryVariables
  >(GetThemeSearchControlValuesDocument, options);
}
export type GetThemeSearchControlValuesQueryHookResult = ReturnType<
  typeof useGetThemeSearchControlValuesQuery
>;
export type GetThemeSearchControlValuesLazyQueryHookResult = ReturnType<
  typeof useGetThemeSearchControlValuesLazyQuery
>;
export type GetThemeSearchControlValuesQueryResult = Apollo.QueryResult<
  GetThemeSearchControlValuesQuery,
  GetThemeSearchControlValuesQueryVariables
>;
