import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { CheckboxGroupFilterOptionFragmentDoc } from 'graphql/searchFilter/CheckboxGroupFilterOption.generated';
export type SrlDynamicOptionsFragment = {
  __typename?: 'FilterComponent';
  flightAirlines: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  provider?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
};

export const SrlDynamicOptionsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SRLDynamicOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightAirlines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    ...CheckboxGroupFilterOptionFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
