import noop from 'lodash/noop';
import React from 'react';
import { PinCluster, MapPinView } from '@hotelplan/components.common.map-pin';
import {
  SrlMapGeoPinFragment,
  SrlMapProductPinFragment,
} from 'graphql/srlMap/SRLMapFragments.generated';
import SRLMapGeoCluster from './SRLMapGeoCluster';
import SRLMapProductCluster from './SRLMapProductCluster';

type Product = SrlMapProductPinFragment;
type Geo = SrlMapGeoPinFragment;

interface ISRLMapItemsProps {
  mapItems: (Product | Geo)[];
  onProductClusterClick?(products: Product[], cluster: PinCluster): void;
  onProductClick?(product: Product, pin: MapPinView<Product>): void;
  onGeoClusterClick?(objects: Geo[], cluster: PinCluster): void;
  onGeoClick?(geo: Geo, pin: MapPinView<Geo>): void;
  onClickOnSelectedPin?(): void;
}

const SRLMapItems: React.FC<ISRLMapItemsProps> = props => {
  const {
    mapItems,
    onProductClusterClick = noop,
    onProductClick = noop,
    onGeoClusterClick = noop,
    onGeoClick = noop,
    onClickOnSelectedPin,
  } = props;

  if (mapItems[0]?.__typename === 'SrlMapProductPin') {
    return (
      <SRLMapProductCluster
        products={mapItems as Product[]}
        onClusterClick={onProductClusterClick}
        onProductClick={onProductClick}
        onClickOnSelectedPin={onClickOnSelectedPin}
      />
    );
  }

  if (mapItems[0]?.__typename === 'SrlMapGeoPin') {
    return (
      <SRLMapGeoCluster
        objects={mapItems as Geo[]}
        onClusterClick={onGeoClusterClick}
        onGeoClick={onGeoClick}
      />
    );
  }

  return null;
};

export default SRLMapItems;
