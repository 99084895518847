import { useGoogleMap } from '@react-google-maps/api';
import React, { useEffect } from 'react';
import type { ISelectableItem } from '@hotelplan/components.common.map-pin';
import { mapCoordinatesToLatLng } from '@hotelplan/components.common.map-pin';
import {
  MapRadiusSearchFilter,
  TMapPlace,
} from '@hotelplan/components.common.map-radius-search';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useSearchState } from '@hotelplan/libs.search-state';
import { useTrackFiltersExceptions } from '@hotelplan/libs.tracking';
import { getThemesFromDestinations } from 'components/domain/destinationStack/DestinationStack.mappers';
import { useDestinationStack } from 'components/domain/destinationStack/DestinationStackContext';
import MapControlsOverlay from 'components/domain/map/MapControlsOverlay';
import MapCurrentZoomControl from 'components/domain/map/MapCurrentZoomControl';
import MapItemsCountControl from 'components/domain/map/MapItemsCountControl';
import { ISRLState } from 'components/domain/srl/SRL.types';
import {
  SrlMapGeoPinFragment,
  SrlMapProductPinFragment,
} from 'graphql/srlMap/SRLMapFragments.generated';
import { mapGeoObjectToCanonicalDestination } from './SRLMap.mappers';
import SRLMapItems from './SRLMapItems';
import useSRLMapItems from './useSRLMapItems';

type Product = SrlMapProductPinFragment;
type Geo = SrlMapGeoPinFragment;

interface ISRLMapProps {
  onSelect?(objects: (Geo | Product)[], selectableItem: ISelectableItem): void;
  onClickOnSelectedPin?(): void;
}

const SRLMap: React.FC<ISRLMapProps> = ({ onSelect, onClickOnSelectedPin }) => {
  const map = useGoogleMap();
  const { mobile } = useDeviceType();

  const {
    state: { filters },
    setState,
  } = useSearchState<ISRLState>();

  const { current: destinations, position, stack, go } = useDestinationStack();

  // get themes from initial destinations list
  const themes = getThemesFromDestinations(stack[0]);

  const { loading, mapItems, error } = useSRLMapItems({
    destinations,
    onCompleted({ srl }) {
      const pins = srl.search.mapPins;

      const cheapestPin = pins.productPins.length
        ? pins.cheapestProduct
        : pins.cheapestGeo;

      if (
        cheapestPin &&
        cheapestPin.coordinates.latitude + cheapestPin.coordinates.longitude > 0
      ) {
        map?.setCenter(mapCoordinatesToLatLng(cheapestPin.coordinates));
      }
    },
  });

  useTrackFiltersExceptions(mapItems?.length || 0, loading, error);

  useEffect(
    function changePositionMapEffect() {
      if (!map || !google || !position) return;

      const { coordinates, zoom } = position;

      map.setCenter({ lat: coordinates.latitude, lng: coordinates.longitude });
      map.setZoom(zoom);
    },
    [position, map]
  );

  function onGeoClick(object: Geo) {
    go([...themes, mapGeoObjectToCanonicalDestination(object.geo)], {
      zoom: object.zoom,
      coordinates: object.coordinates,
    });
  }

  function setRadiusSearchState(place: TMapPlace, radius: number) {
    setState(prevState => {
      if (!place && !prevState.filters.radius?.center) return prevState;
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          radius: place
            ? {
                radius,
                label: place.name,
                center: place.coordinates,
              }
            : null,
        },
      };
    });
  }

  return (
    <MapControlsOverlay>
      <MapItemsCountControl loading={loading} count={mapItems.length} />
      <MapCurrentZoomControl />
      {!mobile && (
        <MapRadiusSearchFilter
          radiusFilter={filters.radius}
          onChange={setRadiusSearchState}
        />
      )}
      <SRLMapItems
        mapItems={mapItems}
        onProductClick={(product, pin) => onSelect?.([product], pin)}
        onGeoClick={onGeoClick}
        onClickOnSelectedPin={onClickOnSelectedPin}
      />
    </MapControlsOverlay>
  );
};

export default SRLMap;
