import React from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FeatureTooltipsProvider } from '@hotelplan/libs.context.feature-tooltips';
import { useSearchState } from '@hotelplan/libs.search-state';
import { mapSearchControlToCriteria } from 'components/domain/searchControl/SearchControl.mappers';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/srl/SRL.mappers';
import { ISRLState } from 'components/domain/srl/SRL.types';
import SRLProductItem from 'components/domain/srl/SRLProductItem';
import { useGetSrlMapCardsQuery } from 'graphql/srlMap/GetSRLMapCards.generated';
import { ProductMapItemFragment } from 'graphql/srlMap/ProductMapItemFragment.generated';
import { SrlMapSingleResultFragment } from 'graphql/srlMap/SRLMapFragments.generated';
import { ProductPin } from './SRLMap.types';

interface ISRLMapResultsProps {
  items: ProductPin[];
  onClose?(): void;
}

function cardIsProduct(
  card: SrlMapSingleResultFragment['items'][0]
): card is ProductMapItemFragment {
  return card.__typename === 'SrlProductItem';
}

const MapCardList = styled.ul.attrs({
  'data-id': `map-cards-list`,
})(({ theme: { media } }) => ({
  [media.tablet]: {
    marginTop: '30px',
  },
}));

const ProductCard = styled(SRLProductItem)({
  marginTop: '20px',
  '.offer-close-btn': {
    left: 'auto',
    right: '8px',
    '.icon': {
      height: '20px',
      width: '20px',
    },
  },
});

const SRLMapResults: React.FC<ISRLMapResultsProps> = ({ items, onClose }) => {
  const { mobile } = useDeviceType();

  const {
    loading,
    state: { searchControl, filters, subGeoFilters },
  } = useSearchState<ISRLState>();

  const productIds = items.map(item => item.id);

  const { data: cardsData } = useGetSrlMapCardsQuery({
    skip: loading || !items.length,
    variables: {
      searchControl: mapSearchControlToCriteria(searchControl),
      filters: mapSrlFilterValuesToSrlFilterCriteriaInput(filters),
      subGeoFilters,
      productIds,
    },
  });

  const allCards = cardsData?.srl.search.mapProductCards;
  let cards: ProductMapItemFragment[] | null = null;

  if (allCards?.__typename === 'SrlSingleResult') {
    cards = allCards.items.filter(cardIsProduct);
  }

  if (!cards) {
    return null;
  }

  return (
    <FeatureTooltipsProvider
      maxTooltipHeightPx={140}
      preventContainerOverlapping={mobile}
    >
      <MapCardList>
        {cards.map((card, i) => {
          return (
            <li key={i}>
              <ProductCard
                openMethod={'_blank' as const}
                product={card}
                onClose={onClose}
                isThirdProductCardVariant
              />
            </li>
          );
        })}
      </MapCardList>
    </FeatureTooltipsProvider>
  );
};

export default SRLMapResults;
