import { TravelType } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import { useGetSrlDefaultFilterValuesQuery } from 'graphql/srl/GetSRLDefaultFilterValues.generated';
import type { ISRLState } from './SRL.types';

export default function useGetSrlDefaultFilterValues() {
  const { state, loading: stateLoading } = useSearchState<ISRLState>();
  const { data, loading: valuesLoading } = useGetSrlDefaultFilterValuesQuery({
    ssr: false,
  });

  return {
    filters:
      state.searchControl?.travelType === TravelType.Hotel
        ? data?.srl.hotelControls.mainFilter
        : data?.srl.packageControls.mainFilter,
    loading: stateLoading || valuesLoading,
  };
}
