import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { TextBlockInfoStylesFragmentDoc } from 'graphql/fragments/TextBlockInfoStyles.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetThemeContentQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetThemeContentQuery = {
  __typename?: 'Query';
  theme: {
    __typename?: 'ThemeContext';
    staticContent: {
      __typename?: 'ThemeStaticContent';
      mapAccess: boolean;
      hero: {
        __typename?: 'Image';
        alt: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'ResizedImage';
          url: string;
          id: string;
        }>;
      };
      information: {
        __typename?: 'TextComponent';
        title: string;
        text: string;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      };
    };
  };
};

export const GetThemeContentDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetThemeContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'staticContent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hero' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'criteria' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: '1x_desktop',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: {
                                        kind: 'IntValue',
                                        value: '1920',
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '567' },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: '2x_desktop',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: {
                                        kind: 'IntValue',
                                        value: '3840',
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: {
                                        kind: 'IntValue',
                                        value: '1134',
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: '1x_mobile',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '768' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '235' },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: '2x_mobile',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: {
                                        kind: 'IntValue',
                                        value: '1536',
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '465' },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ResizedImageWithMeta',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'information' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'textBlockInfoStyles',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mapAccess' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...TextBlockInfoStylesFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetThemeContentQuery__
 *
 * To run a query within a React component, call `useGetThemeContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetThemeContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetThemeContentQuery,
    GetThemeContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetThemeContentQuery,
    GetThemeContentQueryVariables
  >(GetThemeContentDocument, options);
}
export function useGetThemeContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetThemeContentQuery,
    GetThemeContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetThemeContentQuery,
    GetThemeContentQueryVariables
  >(GetThemeContentDocument, options);
}
export type GetThemeContentQueryHookResult = ReturnType<
  typeof useGetThemeContentQuery
>;
export type GetThemeContentLazyQueryHookResult = ReturnType<
  typeof useGetThemeContentLazyQuery
>;
export type GetThemeContentQueryResult = Apollo.QueryResult<
  GetThemeContentQuery,
  GetThemeContentQueryVariables
>;
