import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlRoomTypesFilterCountsQueryVariables = Types.Exact<{
  searchControl: Types.SrlSearchControlCriteriaInput;
  subGeoFilters?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  filters?: Types.InputMaybe<Types.SrlFilterCriteriaInput>;
  urlParameter?: Types.InputMaybe<Types.Scalars['String']>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetSrlRoomTypesFilterCountsQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    searchControls: {
      __typename?: 'SrlSearchControlsContext';
      mainFilter: {
        __typename?: 'FilterComponent';
        roomTypes: {
          __typename?: 'CheckboxFilterComponent';
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            productCount: number;
          }>;
        };
      };
    };
  };
};

export const GetSrlRoomTypesFilterCountsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSRLRoomTypesFilterCounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SrlSearchControlCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subGeoFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlFilterCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'urlParameter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchControls' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urlParameter' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchControl' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchControl' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subGeoFilter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'subGeoFilters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainFilter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'roomTypes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'productCount',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useGetSrlRoomTypesFilterCountsQuery__
 *
 * To run a query within a React component, call `useGetSrlRoomTypesFilterCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlRoomTypesFilterCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlRoomTypesFilterCountsQuery({
 *   variables: {
 *      searchControl: // value for 'searchControl'
 *      subGeoFilters: // value for 'subGeoFilters'
 *      filters: // value for 'filters'
 *      urlParameter: // value for 'urlParameter'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetSrlRoomTypesFilterCountsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSrlRoomTypesFilterCountsQuery,
    GetSrlRoomTypesFilterCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlRoomTypesFilterCountsQuery,
    GetSrlRoomTypesFilterCountsQueryVariables
  >(GetSrlRoomTypesFilterCountsDocument, options);
}
export function useGetSrlRoomTypesFilterCountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlRoomTypesFilterCountsQuery,
    GetSrlRoomTypesFilterCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlRoomTypesFilterCountsQuery,
    GetSrlRoomTypesFilterCountsQueryVariables
  >(GetSrlRoomTypesFilterCountsDocument, options);
}
export type GetSrlRoomTypesFilterCountsQueryHookResult = ReturnType<
  typeof useGetSrlRoomTypesFilterCountsQuery
>;
export type GetSrlRoomTypesFilterCountsLazyQueryHookResult = ReturnType<
  typeof useGetSrlRoomTypesFilterCountsLazyQuery
>;
export type GetSrlRoomTypesFilterCountsQueryResult = Apollo.QueryResult<
  GetSrlRoomTypesFilterCountsQuery,
  GetSrlRoomTypesFilterCountsQueryVariables
>;
