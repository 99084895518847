import React, { useEffect } from 'react';
import { useSearchState } from '@hotelplan/libs.search-state';
import type { ISRLState } from 'components/domain/srl/SRL.types';
import SRLMapForm from './SRLMapForm';

// Wrapper to reset filters on map modal closing
const SRLMapAccessForm: React.FC = () => {
  const { state, setState } = useSearchState<ISRLState>();

  useEffect(function resetFiltersOnUnmount() {
    return function unmount(): void {
      setState({
        ...state,
        filters: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SRLMapForm />;
};

export default SRLMapAccessForm;
