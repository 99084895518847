import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const AccordionWrapper = styled.div(
  ({ theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      ...FONT_SIZE.LARGE,
      maxWidth: [null, '800px'],
      mx: [null, 'auto'],
      px: [2, 0],
      '.accordionButton': {
        ...FONT_SIZE.LARGE_BOLD,
        pl: ['20px', 4],
        pr: '20px',
      },
      mb: [5, '50px'],
      '.accordionButton.active': {
        backgroundColor: 'lightGreySixth',
      },
      '.accordionContent.active': {
        backgroundColor: 'white',
        mx: 3,
      },
      '.accordionContent': {
        px: [1, '10px'],
      },
    }),
  ({ theme }) => ({
    '.accordion-rich-text': {
      padding: theme.space[3],
      ...theme.text.richText,
    },
  })
);

export const DynamicAccordionWrapper = styled(AccordionWrapper)(
  sx2CssThemeFn({
    mb: 0,
  })
);
