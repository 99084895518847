import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { PriceFragmentFragmentDoc } from 'graphql/price/Price.generated';
import { CoordinatesFragmentDoc } from './Coordinates.generated';
import { GeoFragmentFragmentDoc } from 'graphql/geo/Geo.generated';
import { ProductMapItemFragmentDoc } from './ProductMapItemFragment.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
export type SrlMapProductPinFragment = {
  __typename?: 'SrlMapProductPin';
  id: string;
  name: string;
  top: boolean;
  zoom: number;
  price: { __typename?: 'Price'; amount: string; currency: string };
  coordinates: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  };
};

export type SrlMapGeoPinFragment = {
  __typename?: 'SrlMapGeoPin';
  zoom: number;
  geo: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  };
  price: { __typename?: 'Price'; amount: string; currency: string };
  coordinates: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  };
};

export type SrlMapSingleResultFragment = {
  __typename?: 'SrlSingleResult';
  status: Types.Status;
  message?: string | null;
  items: Array<
    | { __typename?: 'NudgeItem' }
    | {
        __typename?: 'SrlProductItem';
        mapZoom: number;
        giata: string;
        adults: number;
        children: number;
        hpRating?: number | null;
        taRating?: number | null;
        provider?: string | null;
        productCode?: string | null;
        type: Types.ProductType;
        distanceInfo?: string | null;
        duration: number;
        disrupter?: string | null;
        mealType: string;
        name: string;
        departureDate: string;
        returnDate: string;
        productClass: Types.ProductClass;
        fromTo?: string | null;
        offerId: string;
        inWishlist: boolean;
        transferIncluded?: boolean | null;
        taReviews?: number | null;
        coordinates: {
          __typename?: 'GeoCoordinates';
          latitude: number;
          longitude: number;
        };
        image: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        geoBreadcrumbs: Array<{
          __typename?: 'GeoObject';
          id: string;
          name: string;
          type: Types.GeoType;
        }>;
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
        rooms?: Array<{
          __typename?: 'Room';
          boardType?: string | null;
          bookingCode?: string | null;
          roomType?: string | null;
        } | null> | null;
        featureSummary: Array<{
          __typename?: 'ProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'ProductFeature';
            id: string;
            name: string;
            enabled?: boolean | null;
          }>;
        }>;
        price: { __typename?: 'Price'; amount: string; currency: string };
        totalPrice: { __typename?: 'Price'; amount: string; currency: string };
        wishlistItem?: {
          __typename?: 'WishlistItem';
          id: string;
          inWishlist: boolean;
        } | null;
        transferPrice?: {
          __typename?: 'Price';
          amount: string;
          currency: string;
        } | null;
      }
  >;
};

export type SrlMapEmptyResultFragment = {
  __typename?: 'SrlEmptyResult';
  status: Types.Status;
  message?: string | null;
  altTravelType: Types.TravelType;
  alternativeSearchUrl?: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  } | null;
};

export const SrlMapProductPinFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlMapProductPin' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlMapProductPin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PriceFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'top' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'coordinates' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoom' } },
        ],
      },
    },
    ...PriceFragmentFragmentDoc.definitions,
    ...CoordinatesFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const SrlMapGeoPinFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlMapGeoPin' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlMapGeoPin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geoFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PriceFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'coordinates' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoom' } },
        ],
      },
    },
    ...GeoFragmentFragmentDoc.definitions,
    ...PriceFragmentFragmentDoc.definitions,
    ...CoordinatesFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const SrlMapSingleResultFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlMapSingleResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlSingleResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productMapItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProductMapItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const SrlMapEmptyResultFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlMapEmptyResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlEmptyResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altTravelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alternativeSearchUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
