import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { CheckboxGroupFilterValueFragmentDoc } from './CheckboxGroupFilterValue.generated';
import { SliderFilterFragmentDoc } from './SliderFilter.generated';
import { SingleValueFilterFragmentDoc } from './SingleValueFilterFragment.generated';
import { RadiobuttonGroupFilterValueFragmentDoc } from './RadiobuttonGroupFilterValue.generated';
import { RadiusFilterFragmentDoc } from './RadiusFilter.generated';
export type UrlFilterValuesFragment = {
  __typename?: 'FilterComponent';
  departureWeekdays: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  arrivalWeekdays: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  arrivalAirports: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  minPrice: {
    __typename?: 'SliderFilterComponent';
    caption: string;
    selected: string;
  };
  productCode?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  provider?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  flightProvider?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  refundableFeatures: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  directFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  returnFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  flightAirlines: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  radius?: {
    __typename?: 'RadiusFilterComponent';
    label?: string | null;
    radius: number;
    center: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
  } | null;
};

export const UrlFilterValuesFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'URLFilterValues' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'departureWeekdays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'arrivalWeekdays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'arrivalAirports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SliderFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'singleValueFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'singleValueFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightProvider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'singleValueFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundableFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directFlightArrivalTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlightArrivalTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightAirlines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'radius' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'radiusFilter' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CheckboxGroupFilterValueFragmentDoc.definitions,
    ...SliderFilterFragmentDoc.definitions,
    ...SingleValueFilterFragmentDoc.definitions,
    ...RadiobuttonGroupFilterValueFragmentDoc.definitions,
    ...RadiusFilterFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
