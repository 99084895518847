import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import {
  SrlMapSingleResultFragmentDoc,
  SrlMapEmptyResultFragmentDoc,
} from './SRLMapFragments.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlMapCardsQueryVariables = Types.Exact<{
  productIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
  searchControl: Types.SrlSearchControlCriteriaInput;
  subGeoFilters?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  filters?: Types.InputMaybe<Types.SrlFilterCriteriaInput>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetSrlMapCardsQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    search: {
      __typename?: 'SrlResultContext';
      mapProductCards:
        | {
            __typename?: 'SrlEmptyResult';
            status: Types.Status;
            message?: string | null;
            altTravelType: Types.TravelType;
            alternativeSearchUrl?: {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            } | null;
          }
        | {
            __typename?: 'SrlSingleResult';
            status: Types.Status;
            message?: string | null;
            items: Array<
              | { __typename?: 'NudgeItem' }
              | {
                  __typename?: 'SrlProductItem';
                  mapZoom: number;
                  giata: string;
                  adults: number;
                  children: number;
                  hpRating?: number | null;
                  taRating?: number | null;
                  provider?: string | null;
                  productCode?: string | null;
                  type: Types.ProductType;
                  distanceInfo?: string | null;
                  duration: number;
                  disrupter?: string | null;
                  mealType: string;
                  name: string;
                  departureDate: string;
                  returnDate: string;
                  productClass: Types.ProductClass;
                  fromTo?: string | null;
                  offerId: string;
                  inWishlist: boolean;
                  transferIncluded?: boolean | null;
                  taReviews?: number | null;
                  coordinates: {
                    __typename?: 'GeoCoordinates';
                    latitude: number;
                    longitude: number;
                  };
                  image: {
                    __typename?: 'Image';
                    alt: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'ResizedImage';
                      url: string;
                      id: string;
                    }>;
                  };
                  geoBreadcrumbs: Array<{
                    __typename?: 'GeoObject';
                    id: string;
                    name: string;
                    type: Types.GeoType;
                  }>;
                  link: {
                    __typename?: 'InternalLink';
                    caption?: string | null;
                    uri: string;
                    targetPageType: Types.PageType;
                  };
                  rooms?: Array<{
                    __typename?: 'Room';
                    boardType?: string | null;
                    bookingCode?: string | null;
                    roomType?: string | null;
                  } | null> | null;
                  featureSummary: Array<{
                    __typename?: 'ProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'ProductFeature';
                      id: string;
                      name: string;
                      enabled?: boolean | null;
                    }>;
                  }>;
                  price: {
                    __typename?: 'Price';
                    amount: string;
                    currency: string;
                  };
                  totalPrice: {
                    __typename?: 'Price';
                    amount: string;
                    currency: string;
                  };
                  wishlistItem?: {
                    __typename?: 'WishlistItem';
                    id: string;
                    inWishlist: boolean;
                  } | null;
                  transferPrice?: {
                    __typename?: 'Price';
                    amount: string;
                    currency: string;
                  } | null;
                }
            >;
          };
    };
  };
};

export const GetSrlMapCardsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSRLMapCards' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SrlSearchControlCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subGeoFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlFilterCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'search' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchControl' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchControl' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subGeoFilter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'subGeoFilters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mapProductCards' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'productIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'productIds' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'srlMapSingleResult',
                              },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'srlMapEmptyResult',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SrlMapSingleResultFragmentDoc.definitions,
    ...SrlMapEmptyResultFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetSrlMapCardsQuery__
 *
 * To run a query within a React component, call `useGetSrlMapCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlMapCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlMapCardsQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      searchControl: // value for 'searchControl'
 *      subGeoFilters: // value for 'subGeoFilters'
 *      filters: // value for 'filters'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetSrlMapCardsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSrlMapCardsQuery,
    GetSrlMapCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlMapCardsQuery,
    GetSrlMapCardsQueryVariables
  >(GetSrlMapCardsDocument, options);
}
export function useGetSrlMapCardsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlMapCardsQuery,
    GetSrlMapCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlMapCardsQuery,
    GetSrlMapCardsQueryVariables
  >(GetSrlMapCardsDocument, options);
}
export type GetSrlMapCardsQueryHookResult = ReturnType<
  typeof useGetSrlMapCardsQuery
>;
export type GetSrlMapCardsLazyQueryHookResult = ReturnType<
  typeof useGetSrlMapCardsLazyQuery
>;
export type GetSrlMapCardsQueryResult = Apollo.QueryResult<
  GetSrlMapCardsQuery,
  GetSrlMapCardsQueryVariables
>;
