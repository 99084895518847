import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';
import pipe from 'lodash/fp/pipe';
import dynamic from 'next/dynamic';
import React from 'react';
import { Bd4TravelRecommendationFragment } from 'graphql/bd4Travel/BD4TravelRecommendation.generated';
import { BlogArticleFragment } from 'graphql/blogArticle/BlogArticle.generated';
import { FaqFragment } from 'graphql/dynamic-components/GetDynamicComponents.generated';
import { FlightRecommendationWithoutPriceFragment } from 'graphql/flight/FlightRecommendationWithoutPrice.generated';
import { FlightRecommendationWithPriceFragment } from 'graphql/flight/FlightRecommendationWithPrice.generated';
import { GeoRecommendationFragment } from 'graphql/geo/GeoRecommendation.generated';
import { MarketingRecommendationFragment } from 'graphql/marketing/MarketingRecommendation.generated';
import { ProductRecommendationFragment } from 'graphql/product/ProductRecommendation.generated';
import { ThemeRecommendationFragment } from 'graphql/theme/ThemeRecommendation.generated';

export type TRecommendationType = TRecommendation['__typename'];

export type TRecommendation =
  | FaqFragment
  | BlogArticleFragment
  | MarketingRecommendationFragment
  | ThemeRecommendationFragment
  | GeoRecommendationFragment
  | ProductRecommendationFragment
  | Bd4TravelRecommendationFragment
  | FlightRecommendationWithPriceFragment
  | FlightRecommendationWithoutPriceFragment;

type TRecommendationProps = {
  item: TRecommendation;
  itemsLength?: number;
  index?: number;
  groupName?: string;
  typename?: TRecommendationType;
};

const MarketingRecommendation = dynamic<TRecommendationProps>(
  () =>
    import(
      'components/domain/recommendationGroup/elements/MarketingRecommendationWrapper'
    )
);
const ProductRecommendation = dynamic<TRecommendationProps>(
  () =>
    import(
      'components/domain/recommendationGroup/elements/ProductRecommendationWrapper'
    )
);
const FlightRecommendationWithPrice = dynamic<TRecommendationProps>(
  () =>
    import(
      'components/domain/recommendationGroup/elements/FlightRecommendationWithPriceWrapper'
    )
);
const GeoRecommendation = dynamic<TRecommendationProps>(
  () => import('components/domain/geo/recommendations/GeoRecommendation')
);
const ThemeRecommendation = dynamic<TRecommendationProps>(
  () => import('components/domain/theme/ThemeRecommendation')
);
const BlogArticleRecommendation = dynamic<TRecommendationProps>(
  () => import('components/domain/blogArticle/BlogArticleRecommendation')
);
const FlightRecommendationWithoutPrice = dynamic<TRecommendationProps>(
  () =>
    import(
      'components/domain/recommendationGroup/elements/FlightRecommendationWithoutPriceWrapper'
    )
);

const isEmptyRecommendation = pipe(omit([`__typename`]), isEmpty);

const componentsMap = new Map<
  TRecommendationType,
  React.ComponentType<TRecommendationProps>
>([
  [`MarketingRecommendationItem`, MarketingRecommendation],
  [`ProductRecommendationItem`, ProductRecommendation],
  [`BD4TravelRecommendation`, ProductRecommendation],
  [`FlightRecommendationWithPriceItem`, FlightRecommendationWithPrice],
  [`GeoRecommendationItem`, GeoRecommendation],
  [`ThemeRecommendationItem`, ThemeRecommendation],
  [`BlogArticleRecommendationItem`, BlogArticleRecommendation],
  [`FlightRecommendationWithoutPriceItem`, FlightRecommendationWithoutPrice],
]);

export const getRecommendationComponent = (
  name: string,
  itemsLength: number
) => (item: TRecommendation, i: number) =>
  getRecommendationComponentRender(name, itemsLength, item, i);

const getRecommendationComponentRender = (
  name: string,
  itemsLength: number,
  recommendation: TRecommendation,
  i: number
) => {
  if (isEmptyRecommendation(recommendation)) {
    // eslint-disable-next-line no-console
    console.warn(`Recommendation ${recommendation.__typename} is empty`);
    return null;
  }

  const Component = componentsMap.get(recommendation.__typename);

  return Component ? (
    <Component
      item={recommendation}
      index={i}
      itemsLength={itemsLength}
      groupName={name}
    />
  ) : null;
};
