import { Image } from '@hotelplan/graphql.types';

export enum StyleSettingsDynamicComponents {
  FONT_COLOR = 'fontColor',
  BACKGROUND_COLOR = 'backgroundColor',
}

export const getStyle = <T>(
  component: T,
  styleName: StyleSettingsDynamicComponents
): string | null => {
  if (
    component[styleName] !== undefined &&
    (styleName === StyleSettingsDynamicComponents.FONT_COLOR ||
      styleName === StyleSettingsDynamicComponents.BACKGROUND_COLOR)
  ) {
    return component[styleName];
  }

  return null;
};

export const getBackgroundImage = <T>(component: T): Image | null => {
  if (component['backgroundImage'] !== undefined) {
    return component['backgroundImage'];
  }

  return null;
};

export const getBottomPadding = (bottomPadding: string) =>
  bottomPadding === 'PADDING_NONE'
    ? 'none-padding-bottom'
    : bottomPadding === 'PADDING_SMALL'
    ? 'small-padding-bottom'
    : 'large-padding-bottom';

export const getTopPadding = (topPadding: string) =>
  topPadding === 'PADDING_NONE'
    ? 'none-padding-top'
    : topPadding === 'PADDING_SMALL'
    ? 'small-padding-top'
    : 'large-padding-top';
