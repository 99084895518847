import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Form, IFormApi, IFormProps } from '@hotelplan/components.common.forms';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  useSearchState,
  useOnSearchStateChanges,
} from '@hotelplan/libs.search-state';
import { mapMainFilterComponentValuesToFiltersFormState } from 'components/domain/filters/Filters.mappers';
import FiltersSkeleton from 'components/domain/filters/Filters.skeleton';
import type { IFiltersFormState } from 'components/domain/filters/Filters.types';
import useFiltersSubmitController from 'components/domain/filters/useFiltersSubmitController';
import {
  changeSRLFiltersInterceptor,
  changeSRLFiltersWithPopularFiltersInterceptor,
} from 'components/domain/srl/SRL.mappers';
import type { ISRLState } from 'components/domain/srl/SRL.types';
import useGetSrlDefaultFilterValues from 'components/domain/srl/useGetSrlDefaultFilterValues';
import usePopularFiltersFeatureToggle from 'components/domain/srl/usePopularFilterFeatureToggle';
import type { MainFilterComponentValuesFragment } from 'graphql/searchFilter/MainFilterComponentValues.generated';

interface ISRLFiltersFormProps extends React.PropsWithChildren<{}> {
  postSubmit?: (state: ISRLState) => void;
  formApiRef?: React.RefObject<IFormApi<IFiltersFormState>>;
  className?: string;
}

const FiltersSubmitController: React.FC<ISRLFiltersFormProps> = ({
  postSubmit,
  children,
}) => {
  const stateContext = useSearchState<ISRLState>();
  // @TODO get rid of any type due to subscribe fn
  useFiltersSubmitController(stateContext as any, postSubmit);

  return <>{children}</>;
};

const FiltersForm = styled(Form)(
  ({ theme }) => theme.forms.filtersForm
) as React.FC<IFormProps<IFiltersFormState>>;

const SRLFiltersForm: React.FC<ISRLFiltersFormProps> = props => {
  const defaultFormApiRef = useRef<IFormApi<IFiltersFormState>>(null);
  const {
    postSubmit,
    formApiRef = defaultFormApiRef,
    children,
    className,
  } = props;

  const { mobile } = useDeviceType();
  const isPopularFilterEnabled = usePopularFiltersFeatureToggle();

  const {
    filters: defaultFilters,
    loading: defaultLoading,
  } = useGetSrlDefaultFilterValues();

  const {
    loading,
    state: { searchControl, filters },
    setState,
  } = useSearchState<ISRLState>();

  const currentTravelTypeRef = useRef(searchControl?.travelType);

  useEffect(() => {
    if (currentTravelTypeRef.current !== searchControl?.travelType && filters) {
      formApiRef?.current?.setValues(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchControl?.travelType]);

  const filtersRef = useRef(filters);

  useEffect(
    function updateFiltersFormStateOnExternalUpdates() {
      if (filters !== filtersRef.current) {
        formApiRef?.current?.setValues(filters);
        filtersRef.current = filters;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  useOnSearchStateChanges<ISRLState>((next, prev) => {
    if (next.searchControl.travelType !== prev.searchControl.travelType) {
      formApiRef?.current?.setValues(filters);
      return;
    }

    if (next.filters) {
      formApiRef.current?.setValues(next.filters);
    }
  });

  const onSubmit = (formState: IFiltersFormState): void => {
    setState(
      prev => ({
        ...prev,
        filters: mobile ? { ...formState, prevChangedFilter: null } : formState,
      }),
      postSubmit
    );
  };

  const onReset =
    !defaultLoading && defaultFilters
      ? (setValues: (defaultValues: IFiltersFormState) => void): void => {
          setValues(
            mapMainFilterComponentValuesToFiltersFormState(
              defaultFilters as MainFilterComponentValuesFragment
            )
          );
        }
      : undefined;

  if (loading || !filters) return <FiltersSkeleton />;

  return (
    <FiltersForm
      formApiRef={formApiRef}
      initialValues={filters}
      changeInterceptor={
        isPopularFilterEnabled
          ? changeSRLFiltersWithPopularFiltersInterceptor
          : changeSRLFiltersInterceptor
      }
      onSubmit={onSubmit}
      onReset={onReset}
      className={className}
    >
      {mobile ? (
        children
      ) : (
        <FiltersSubmitController {...props}>{children}</FiltersSubmitController>
      )}
    </FiltersForm>
  );
};

export default SRLFiltersForm;
